import React from 'react'
import { MenuItem } from '@material-ui/core'
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdCheckmark } from 'react-icons/io'
import moment from 'moment'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import * as _ from 'lodash'

import {
  userRoles,
  profileFields,
  TripTypeCategory,
  rentBoatTrip,
  feedbackSubTopics,
  timeFormats,
  AddressTypeEnum,
  passwordStrengthEnum,
  userRoleTypes,
  providerEnum,
  boatShowStatus,
  responsiveCarouselIcon,
  imgExtension,
  defaultImage,
  boatTypeUserProfile,
  boatOwnersRestriction,
  boatsRoutes,
  marinaRoutes,
  rentRoutes,
  serviceRoutes,
  branchRoutes,
  commonRoutes,
  updateFormsRoutes,
  dealersRoutes,
  withoutDocumentRoutes,
  officeLocationAccessibleRole,
  articleRoutes,
  brokersRoute,
  pagination,
  boatType,
  marketVerifications,
  marketTypes,
  paymentMethodAccessibleRoles,
  titleEnums,
} from '../util/enums/enums'
import { getLocalInfo, getLocalStorageItem } from './storageHelper'
import { nameFormatter, lowerHypenCase, displayDefaultImage } from './string'
import { rentDateDisplayFormat, checkDateIsAfterCurrent, checkDateIsBeforeCurrent } from './dateTimeHelper'
import { viewBoatHandler, viewRentBoatHandler, viewServiceBoatHandler, viewMarinaDetails } from './boatHelper'
import { CommonTooltip } from '../components/CommonTooltip'
import { urlParamsToObject } from './routeHelper'
import { get } from 'lodash'
import { getId } from '../util/utilFunctions'

export const renderMenuItems = (values = []) => {
  if (values && values.length) {
    return values.map(
      name =>
        name &&
        name.lookUp && (
          <option key={name.lookUp.id} value={name.lookUp.id}>
            {name.lookUp.alias}
          </option>
        )
    )
  }
}

export const renderMenuItemsRentCard = (values = []) => {
  if (values && values.length) {
    return values.map(
      name =>
        name && (
          <MenuItem key={name.id} value={name.id}>
            {name.alias}
          </MenuItem>
        )
    )
  }
}

export const renderErrorMessage = (message, className, isError, passwordStrength = '') => {
  return (
    <div className={className}>
      <span className={`mr-2 register-password-hint-icon psw--icon--${passwordStrength}`}>
        {isError ? <AiOutlineClose /> : <IoMdCheckmark />}
      </span>
      <span className={`register-password-hint-text  psw--${passwordStrength}`}>{message} </span>
      {passwordStrength && (
        <span className={`password-strength-type psw--${passwordStrength} font-weight-bold`}>{passwordStrength}</span>
      )}
    </div>
  )
}

export const renderBoatTypes = (boatTypes = []) => {
  if (boatTypes && boatTypes.length) {
    return boatTypes.map(type => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    ))
  }
}

export const getBoatPassengerText = selectedTrip => {
  if (selectedTrip) {
    switch (selectedTrip) {
      case 'Private Trip':
        return 'Group Size'
      case 'Rent Per Hour':
        return 'Add Watercrafts'
      case 'Shared Trip':
        return 'Add Guests'
      default:
        return 'Boat capacity'
    }
  }
}

export const getSelectedTrip = type => {
  const selectedTrip =
    type === TripTypeCategory.private
      ? rentBoatTrip.PRIVATE_TRIP
      : type === TripTypeCategory.shared
      ? rentBoatTrip.SHARED_TRIP
      : type === TripTypeCategory.rentPerHour
      ? rentBoatTrip.RENT_PER_HOUR
      : ''
  return selectedTrip
}

export const renderRentBoatTypes = (type, boatRentTypes) => {
  const selectedTrip =
    type === TripTypeCategory.private
      ? rentBoatTrip.PRIVATE_TRIP
      : type === TripTypeCategory.shared
      ? rentBoatTrip.SHARED_TRIP
      : type === TripTypeCategory.rentPerHour
      ? rentBoatTrip.RENT_PER_HOUR
      : ''
  if (boatRentTypes && boatRentTypes.length) {
    return boatRentTypes.map(type => {
      return type.tripId.map(tripId => {
        if (tripId.alias === selectedTrip) {
          return (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          )
        }
      })
    })
  }
}

export const renderYachtServicesMenuItems = (values = []) => {
  if (values && values.length) {
    return values.map(item => {
      return (
        <MenuItem className="drop---item" key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      )
    })
  }
}

export const renderMarinaServicesMenuItems = (values = []) => {
  if (values && values.length) {
    return values.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ))
  }
}

export const getUserProfileCount = user => {
  const role = (getLocalInfo('role') && getLocalInfo('role').aliasName) || user.role.aliasName

  switch (role) {
    case userRoles.MEMBER:
      const total = profileFields[role]
      return getUserProfilePercentage(user, total)

    case userRoles.BOAT_OWNER:
      const boatOwnerTotal = profileFields[role]
      return getUserProfilePercentage(user, boatOwnerTotal)

    case userRoles.BROKER_AND_DEALER:
      const brokerTotal = profileFields[role]
      return getUserProfilePercentage(user, brokerTotal)

    case userRoles.MARINA_AND_STORAGE:
      const marinaTotal = profileFields[role]
      return getUserProfilePercentage(user, marinaTotal)

    case userRoles.RENT_AND_CHARTER:
      const rentTotal = profileFields[role]
      const rentRoleTotal = checkRentUserType(role, user.provider?.alias) ? rentTotal + 4 : rentTotal
      return getUserProfilePercentage(user, rentRoleTotal)

    case userRoles.BOAT_MANUFACTURER:
      const manufacturerTotal = profileFields[role]
      return getUserProfilePercentage(user, manufacturerTotal)

    case userRoles.YACHT_SHIPPER:
      const shipperTotal = profileFields[role]
      return getUserProfilePercentage(user, shipperTotal)

    case userRoles.SURVEYOR:
      const surveyorTotal = profileFields[role]
      return getUserProfilePercentage(user, surveyorTotal)

    case userRoles.SERVICE_AND_MAINTENANCE:
      const serviceTotal = profileFields[role]
      return getUserProfilePercentage(user, serviceTotal)

    case userRoles.AGENT:
      const agentTotal = profileFields[role]
      return getUserProfilePercentage(user, agentTotal)

    default:
      break
  }
}

const countFields = loginUser => {
  let count = 0

  const companySectionAccessibleRole = [
    userRoles.AGENT,
    userRoles.BROKER_AND_DEALER,
    userRoles.BOAT_MANUFACTURER,
    userRoles.YACHT_SHIPPER,
    userRoles.SURVEYOR,
    userRoles.SERVICE_AND_MAINTENANCE,
    userRoles.MARINA_AND_STORAGE,
  ]

  const comLicenceAccessibleRole = [
    userRoles.AGENT,
    userRoles.BROKER_AND_DEALER,
    userRoles.BOAT_MANUFACTURER,
    userRoles.YACHT_SHIPPER,
    userRoles.SURVEYOR,
    userRoles.SERVICE_AND_MAINTENANCE,
    userRoles.MARINA_AND_STORAGE,
  ]

  // const STRIPE_NOT_NEEDED_ROLES = [userRoles.MEMBER, userRoles.MARINA_AND_STORAGE, userRoles.SERVICE_AND_MAINTENANCE]
  const PAYMENT_METHOD_NOT_NEEDED_ROLES = [
    userRoles.AGENT,
    userRoles.BOAT_OWNER,
    userRoles.BROKER_AND_DEALER,
    userRoles.BOAT_MANUFACTURER,
    userRoles.RENT_AND_CHARTER,
    userRoles.SURVEYOR,
    userRoles.YACHT_SHIPPER,
    userRoles.MEMBER
  ]

  const { address, ...restField } = loginUser

  const {
    commonAddress: { country, address1, address2, street, city, zip, state },
    officeAddress: { address1: officeAddress },
  } = getAddress(address)

  const user = { ...restField, country, address1, address2, street, city, zip, state, officeAddress }

  for (const field in user) {
    if (field === 'image' && user[field] !== null && user[field] !== '') {
      count++
    }
    // if (
    //   field === 'is_stripe_profile_completed' &&
    //   !STRIPE_NOT_NEEDED_ROLES.includes(user.role.aliasName) &&
    //   user[field] !== null &&
    //   user[field] !== false
    // ) {
    //   count++
    // }
    // if (field === 'title' && user[field] !== null && user[field] !== '') {
    //   count++
    // }
    if (field === 'firstName' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'lastName' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'language' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'profession' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'shortDescription' && user[field] !== null && user[field] !== '') {
      count++
    }

    if (field === 'country' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'address1' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'street' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'city' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'zip' && user[field] !== null && user[field] !== '') {
      count++
    }
    // if (field === 'state' && user[field] !== null && user[field] !== '') {
    //   count++
    // }

    if (field === 'email' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'mobileNumber' && user[field] !== null && user[field] !== '') {
      count++
    }
    if (field === 'governmentId' && user[field] && user[field].length) {
      count++
    }

    // if (user.role.aliasName === userRoles.MEMBER) {
    //   if (field === 'preference' && user[field] !== null && user[field] !== '') {
    //     count++
    //   }
    // }

    if (user.role.aliasName === userRoles.SURVEYOR) {
      if (field === 'pricePerFt' && user[field] !== null && user[field] !== '') {
        count++
      }
    }

    if (user.role.aliasName === userRoles.RENT_AND_CHARTER) {
      if (field === 'provider' && user[field] !== null && user[field] !== '') {
        count++
      }
    }

    if (user.role.aliasName && officeLocationAccessibleRole.includes(user.role.aliasName)) {
      if (field === 'officeAddress' && user[field] !== null && user[field] !== '') {
        count++
      }
    }

    if (
      field === 'paymentMethodTypes' &&
      !PAYMENT_METHOD_NOT_NEEDED_ROLES.includes(get(user, 'role.aliasName', '')) &&
      user[field].length
    ) {
      count++
    }

    if (
      user.role.aliasName &&
      (companySectionAccessibleRole.includes(user.role.aliasName) ||
        checkRentUserType(user.role.aliasName, user.provider?.alias))
    ) {
      if (field === 'companyName' && user[field] !== null && user[field] !== '') {
        count++
      }
      if (field === 'companyWebsite' && user[field] !== null && user[field] !== '') {
        count++
      }
      if (field === 'companyLogo' && user[field] !== null && user[field] !== '') {
        count++
      }
    }

    if (
      user.role.aliasName &&
      (comLicenceAccessibleRole.includes(user.role.aliasName) || checkRentUserType(user.role.aliasName, user.provider?.alias))
    ) {
      if (field === 'commercialLicence' && user[field] && user[field].length) {
        count++
      }
    }
  }

  return count
}

export const getUserProfilePercentage = (user, total) => {
  const fields = countFields(user)
  const count = (fields * 100) / total
  return count.toFixed(0)
}

export const checkRentUserType = (aliasName, provider) => {
  return aliasName === userRoles.RENT_AND_CHARTER && provider === providerEnum.ORGANIZATION
}

export const cityCountryNameFormatter = (city, country) => {
  return <>{city} {country && <><span className="bullet" /> {country}</>}</>
}

export const getTimeFormat = (data, isDate = false) => {
  if (isDate) {
    return moment(data).format('DD MM YYYY')
  } else {
    return moment(data).format('hh:mm A')
  }
}

export const redirectToUserProfile = (user, history, target, getUrl = false, withOrigin = false, getUrlWithOrigin = true) => {
  if (user) {
    const { companyName, firstName, lastName, address } = user
    const id = getId(user)
    const city = address?.find(a => a.type === 'COMMON_ADDRESS')?.city;

    const seoName = nameFormatter(
      [companyName && removeSlash(companyName), firstName && removeSlash(firstName), lastName && removeSlash(lastName), city],
      ' '
    )

    const url = `${withOrigin ? location.origin : ''}/user-profile/${id}/${lowerHypenCase(seoName)}`
    if (getUrl) return (getUrlWithOrigin ? window.location.origin : '') + url;

    if (target) {
      window.location.href = url
    } else {
      id && history && history.push(url)
    }
  }
}

export const feedbackSubTopicOptions = topic => {
  switch (topic) {
    case feedbackSubTopics.SITE_CONTENT:
      return [
        { id: 'Renting Market', value: 'Renting Market' },
        { id: 'Marina ANd Storage Market', value: 'Marina ANd Storage Market' },
        { id: 'Boat Service Market', value: 'Boat Service Market' },
        { id: 'Website Navigation', value: 'Website Navigation' },
        { id: 'Sales Order Process', value: 'Sales Order Process' },
        { id: 'Site Design', value: 'Site Design' },
        { id: 'Information In Our Website', value: 'Information In Our Website' },
        { id: 'Respond Time', value: 'Respond Time' },
        { id: 'Other', value: 'Other' },
      ]

    case feedbackSubTopics.TECHNICAL_FAULT:
      return [
        { id: 'Browser Problems', value: 'Browser Problems' },
        { id: 'Site Speed', value: 'Site Speed' },
        { id: "Can't Get TO The Page Required", value: "Can't Get TO The Page Required" },
        { id: 'Other', value: 'Other' },
      ]

    case feedbackSubTopics.ONLINE_ACCOUNT:
      return [
        { id: 'Registering/Logging in', value: 'Registering/Logging in' },
        { id: 'Payments', value: 'Payments' },
        { id: 'Customer Service', value: 'Customer Service' },
        { id: 'Posting Advertisement', value: 'Posting Advertisement' },
        { id: 'Posting Ad.', value: 'Posting Ad.' },
        { id: 'Auction Rooms', value: 'Auction Rooms' },
        { id: 'Posting Service', value: 'Posting Service' },
      ]
    default:
      break
  }
}

export const feedbackSiteOptions = () => {
  return [
    {
      id: 'veryHappy',
      value: 'Very Happy',
      image: require('../assets/images/boatInner/veryhappy.png'),
    },

    {
      id: 'happy',
      value: 'Happy',
      image: require('../assets/images/boatInner/happy.png'),
    },

    {
      id: 'ok',
      value: 'Ok',
      image: require('../assets/images/boatInner/silent.png'),
    },

    {
      id: 'unHappy',
      value: 'Unhappy',
      image: require('../assets/images/boatInner/sad.png'),
    },

    {
      id: 'angry',
      value: 'Angry',
      image: require('../assets/images/boatInner/angry.png'),
    },
  ]
}

export const feedbackTopicOptions = () => {
  return [
    { id: 'siteContent', value: 'Site Content' },
    { id: 'technicalFault', value: 'Technical Fault' },
    { id: 'onlineAccount', value: 'Online Account' },
  ]
}

export const feedBackMemberOption = () => {
  return [
    { id: 'Yes', value: 'Yes' },
    { id: 'No', value: 'No' },
  ]
}

export const getAdminFeesPrice = (fee, boatPrice) => {
  return (fee * boatPrice) / 100
}

export const getAvgRating = reviews => {
  let rating = 0
  let totalRating = 0

  reviews &&
    reviews.length &&
    reviews.map(item => {
      totalRating = totalRating + item.rating
      return totalRating
    })

  rating = reviews && reviews.length ? totalRating / reviews.length : 0
  return rating
}

export const shareWithUsers = (data, module) => {
  const object = {
    module,
    receiverIds: data.data.selectedUsers,
    moduleId: data.id,
    manualIds: data.data.addedEmails,
  }
  return object
}

export const shareWithUsersInner = (data, module, id) => {
  const object = {
    module,
    receiverIds: data.selectedUsers,
    moduleId: id,
    manualIds: data.addedEmails,
  }
  return object
}

export const validateRentPerHourTripDetails = (tripDetails, selectedHours) => {
  let startTimeMatched = []
  if (tripDetails && tripDetails.length) {
    selectedHours.map((selected, i) => {
      const startTimeMatch = tripDetails.find(
        item =>
          getHourFormat(item.startTime) === getHourFormat(selected.startTime) &&
          getISOFormat(item.startDate) === getISOFormat(selected.startDate)
      )
      startTimeMatch && startTimeMatched.push({ isMatched: true, index: i })
    })
  }
  return startTimeMatched
}

export const requiredRentPerHourValidation = data => {
  let errors = []
  data.map((item, i) => {
    if (!item.startTime || !item.endTime || (!item.startDate && !item.unit)) {
      const errorObject = {
        startTime: item.startTime ? true : false,
        endTime: item.endTime ? true : false,
        startDate: item.startDate ? true : false,
        unit: item.unit ? true : false,
        index: i,
      }
      errors.push(errorObject)
    }
  })

  return errors
}

export const checkUnitValidation = data => {
  let errors = []

  data.map((item, i) => {
    let errorObject = {
      index: item.index,
    }
    if (!item.unit) {
      errorObject = {
        ...errorObject,
        unit: item.unit ? true : false,
      }
      if (!item.price) {
        errorObject = {
          ...errorObject,
          price: item.price ? true : false,
        }
        errors.push(errorObject)
      }
    }
  })

  return errors
}

export const requiredValidationForPrivateShared = data => {
  let errors = []
  data.map((item, i) => {
    if (!item.members || !item.startTime || !item.endTime || !item.startDate || !item.endDate || !item.type) {
      const errorObject = {
        members: item.members ? true : false,
        startTime: item.startTime ? true : false,
        endTime: item.endTime ? true : false,
        startDate: item.startDate ? true : false,
        endDate: item.endDate ? true : false,
        type: item.type ? true : false,
        price: item.price ? true : false,
        index: i,
      }
      errors.push(errorObject)
    }
  })

  return errors
}

export const findDuplicateDataInSharedPrivate = (data, tripData = []) => {
  let duplicateEntries = []
  let tripDetails = []

  data.map((item, i) => {
    if (tripDetails.length) {
      const duplicateData = tripDetails.find(
        trip =>
          moment(item.startDate).isBetween(moment(trip.startDate), moment(trip.endDate)) ||
          moment(item.endDate).isBetween(moment(trip.startDate), moment(trip.endDate)) ||
          getTimeFormat(item.startDate, true) === getTimeFormat(trip.startDate, true) ||
          getTimeFormat(item.startDate, true) === getTimeFormat(trip.endDate, true) ||
          getTimeFormat(item.endDate, true) === getTimeFormat(trip.endDate, true) ||
          getTimeFormat(item.endDate, true) === getTimeFormat(trip.startDate, true)
      )
      if (duplicateData) {
        duplicateEntries.push({ ...duplicateData, i })
      } else {
        const duplicateData =
          tripData.length &&
          tripData.find(
            trip =>
              moment(item.startDate).isBetween(moment(trip.startDate), moment(trip.endDate)) ||
              moment(item.endDate).isBetween(moment(trip.startDate), moment(trip.endDate)) ||
              getTimeFormat(item.startDate, true) === getTimeFormat(trip.startDate, true) ||
              getTimeFormat(item.startDate, true) === getTimeFormat(trip.endDate, true) ||
              getTimeFormat(item.endDate, true) === getTimeFormat(trip.endDate, true) ||
              getTimeFormat(item.endDate, true) === getTimeFormat(trip.startDate, true)
          )
        if (duplicateData) {
          duplicateEntries.push({ ...duplicateData, i })
        } else {
          tripDetails.push(item)
        }
      }
    } else {
      const duplicateData =
        tripData.length &&
        tripData.find(
          trip =>
            moment(item.startDate).isBetween(moment(trip.startDate), moment(trip.endDate)) ||
            moment(item.endDate).isBetween(moment(trip.startDate), moment(trip.endDate)) ||
            getTimeFormat(item.startDate, true) === getTimeFormat(trip.startDate, true) ||
            getTimeFormat(item.startDate, true) === getTimeFormat(trip.endDate, true) ||
            getTimeFormat(item.endDate, true) === getTimeFormat(trip.endDate, true) ||
            getTimeFormat(item.endDate, true) === getTimeFormat(trip.startDate, true)
        )
      if (duplicateData) {
        duplicateEntries.push({ ...duplicateData, i })
      } else {
        tripDetails.push(item)
      }
    }
  })

  return duplicateEntries
}

export const findDuplicateData = data => {
  let duplicates = []
  let tripDetails = []

  data.map(item => {
    if (tripDetails.length) {
      const duplicateData = tripDetails.find(
        duplicate =>
          getHourFormat(item.startTime) === getHourFormat(duplicate.startTime) &&
          getISOFormat(item.startDate) === getISOFormat(duplicate.startDate)
      )

      if (duplicateData) {
        if (duplicateData.index + 1 !== item.index + 1) {
          duplicates.push(item)
        } else {
          tripDetails.push(item)
        }
      } else {
        tripDetails.push(item)
      }
    } else {
      tripDetails.push(item)
    }
  })

  return duplicates
}

export const getHourFormat = data => {
  return moment(data).hour()
}

export const getISOFormat = data => {
  return moment(data).toISOString()
}

export const getFirstCharacter = string => {
  return string && <div className="text--logo">{string.charAt(0)}</div>
}

export const getCurrentBidPrice = bid => {
  if (bid) {
    const bidPrice = bid.split(' ')
    const intBidPrice = bidPrice[1].split('.')
    return intBidPrice[0].replace(/,/g, '')
  } else {
    return bid
  }
}

export const getBidDuration = bid => {
  if (bid) {
    const bidTime = bid.split(' ')
    if (bidTime && bidTime.length) {
      if (bidTime[1] === timeFormats.hours) {
        if (bidTime[0] < 1) {
          const minutes = bidTime[0] * 60
          return `${minutes} minutes`
        } else {
          return bid
        }
      } else if (bidTime[1] === timeFormats.days) {
        if (bidTime[0] < 1) {
          const hours = bidTime[0] * 24
          if (hours < 1) {
            const minutes = hours * 60
            return `${minutes} minutes`
          } else {
            return `${hours} hours`
          }
        } else {
          return bid
        }
      }
    }
  } else {
    return bid
  }
}

export const getTripType = tripType => {
  const type = tripType && tripType.tripId && tripType.tripId.length === 1 ? 'Rent Per Hour' : 'Private / Shared Trip'
  return type
}

export const getVerifiedCheck = () => {
  return <img loading="lazy" src={require('../assets/images/verif.svg')} className="h-100 w-100 " alt="Verify icon" />
}

export const getAddress = (addressArray = []) => {
  const commonAddress = {
    address1: '',
    address2: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    geometricLocation: null,
  }

  if (addressArray && addressArray.length) {
    return addressArray.reduce(
      (response, item) => {
        if (item.type === AddressTypeEnum.COMMON_ADDRESS || item.type === AddressTypeEnum.JET_SKI_ADDRESS) {
          const { type, ...newItem } = item
          response = { ...response, commonAddress: newItem }
        }

        if (item.type === AddressTypeEnum.OFFICE_ADDRESS) {
          const { type, ...newItem } = item
          response = { ...response, officeAddress: newItem }
        }

        if (item.type === AddressTypeEnum.TRIP_ADDRESS) {
          const { type, ...newItem } = item
          response = { ...response, tripAddress: newItem }
        }

        if (item.type === AddressTypeEnum.GATHERING_POINT) {
          const { type, ...newItem } = item
          response = { ...response, gatheringPoint: newItem }
        }

        return response
      },
      { commonAddress, officeAddress: commonAddress, tripAddress: commonAddress, gatheringPoint: commonAddress }
    )
  } else {
    return {
      commonAddress,
      officeAddress: commonAddress,
      tripAddress: commonAddress,
      gatheringPoint: commonAddress,
    }
  }
}

export const getLatLngFromGeometric = geometricLocation => {
  if (geometricLocation) {
    const { coordinates } = geometricLocation
    if (coordinates.length) {
      const [lng, lat] = coordinates
      return { lat, lng }
    }
  }
  return null
}

export const getAgreementRole = role => {
  const registerRequestRoles = [userRoles.BOAT_MANUFACTURER, userRoles.SURVEYOR, userRoles.YACHT_SHIPPER]
  return registerRequestRoles.includes(role)
}

export const downloadIcon = (mediaID = '', getDocumentUrl, iconClassName) => {
  return (
    <CommonTooltip component={ctProps => (
      <div
        className={iconClassName ? `estimated-icon ${iconClassName}` : 'estimated-icon'}
        onClick={() => getDocumentUrl(mediaID)}
        data-tooltip-id={ctProps.id} data-tooltip-content="Open Document"
      >
          <SaveAltIcon className="cursor-pointer" />

      </div>
    )} />
  )
}

export const checkAgreementId = agreementId => {
  return agreementId.length === 6
}

export const validatePassword = (password, email) => {
  let errorMessages = {
    passwordLength: false,
    capsCount: false,
    smallCount: false,
    errorMessages: false,
  }
  let capsCount, smallCount, symbolCount, numberCount, userEmail, uniqPassword
  if (password.length < 8) {
    errorMessages.passwordLength = true
  }
  userEmail = email && email.toLowerCase()
  uniqPassword = password.toLowerCase()

  capsCount = (password.match(/[A-Z]/g) || []).length
  smallCount = (password.match(/[a-z]/g) || []).length
  symbolCount = (password.match(/[-!$%@^&*()_+|~=`{}\[\]:";'<>?,.\/]/) || []).length
  numberCount = (password.match(/[0-9]/g) || []).length

  if (capsCount < 1) {
    errorMessages.capsCount = true
  }
  if (smallCount < 1) {
    errorMessages.smallCount = true
  }
  if ((symbolCount && numberCount) < 1) {
    errorMessages.symbolOrNumberCount = true
  }

  return errorMessages
}

export const passwordStrength = (password, setValue) => {
  let score = 0
  let passwordStrength
  let regexPositive = [/[A-Z]/g, /[a-z]/g, /[0-9]/g, /[-!$%@^&*()_+|~=`{}\[\]:";'<>?,.\/]/]
  regexPositive.forEach((regex, index) => {
    if (new RegExp(regex).test(password)) {
      score += 1
    }
  })
  switch (score) {
    case 1:
      passwordStrength = passwordStrengthEnum.POOR
      break

    case 2:
      passwordStrength = passwordStrengthEnum.POOR
      break

    case 3:
      passwordStrength = passwordStrengthEnum.AVERAGE
      setValue('password', password)
      break

    case 4:
      passwordStrength = password.length >= 8 ? passwordStrengthEnum.STRONG : passwordStrengthEnum.AVERAGE
      setValue('password', password)
      break

    default:
      passwordStrength = passwordStrengthEnum.WEAK
  }

  return passwordStrength
}

export const getShowAllLinkText = role => {
  const showAllText =
    role.type === userRoleTypes.SELLER
      ? 'Boats'
      : role.aliasName === userRoles.RENT_AND_CHARTER
      ? 'Rent Boats'
      : role.aliasName === userRoles.MARINA_AND_STORAGE
      ? 'Marina And Storage'
      : role && role.aliasName === userRoles.SERVICE_AND_MAINTENANCE
      ? 'Service'
      : role && role.aliasName === userRoles.MEMBER
      ? 'Boat Shows'
      : ''

  return showAllText
}

export const checkTripType = (type, rentTypes) => {
  const rentType = rentTypes.length && rentTypes.find(item => item.id === type)
  const isRentPerHour = rentType && rentType.tripId && rentType.tripId.length === 1
  return isRentPerHour
}

// Note : need to remove after confirmation
export const getRole = role => {
  if (role.aliasName === userRoles.YACHT_SHIPPER) {
    return 'Boat Shipper'
  }
}
// Note : Zoom carousal
export const getCarousalGrid = (windowWidth, defaultNumber = { lg: 4, md: 3, sm: 2 }) => {
  return windowWidth < 1060 ? defaultNumber.sm : windowWidth > 1345 ? defaultNumber.lg : defaultNumber.md
}

//get trips by date
export const getRentPerHourGroupedData = tripDetails => {
  let trips = []
  const dateData = _.groupBy(tripDetails, 'startDate')
  for (const key of Object.keys(dateData)) {
    const dateDetails = {
      date: rentDateDisplayFormat(key),
      tripDetails: dateData[key],
    }
    trips.push(dateDetails)
  }
  return trips
}

//search trip
export const searchTrip = (selectedDate, trips) => {
  const date = rentDateDisplayFormat(selectedDate)
  const selectedTrip = trips && trips.find(trip => trip.date === date)
  return selectedTrip
}

// Margin Grid
export const getMarginGrid = (windowWidth, defaultNumber = { lg: 15, md: 12, sm: 10 }) => {
  return windowWidth < 1060 ? defaultNumber.sm : windowWidth > 1345 ? defaultNumber.lg : defaultNumber.md
}

export const findBoatShowDuration = (startDate, endDate) => {
  if (checkDateIsAfterCurrent(startDate)) {
    return boatShowStatus.COMING_SOON
  }
  if (checkDateIsBeforeCurrent(endDate)) {
    return boatShowStatus.OVER
  }
  if (checkDateIsBeforeCurrent(startDate) && checkDateIsAfterCurrent(endDate)) {
    return boatShowStatus.ON_GOING
  }
}

// Note : Margin Grid
export const getCarousalIcon = () => {
  const width = document.querySelector('.section-heading')
  return width
    ? width.offsetWidth < 1060
      ? responsiveCarouselIcon.sm
      : width.offsetWidth > 1345
      ? responsiveCarouselIcon.lg
      : responsiveCarouselIcon.md
    : 38
}
/**
 * Role Wise key set for Boat
 * module:User Profile
 *  */
export const getUserProfileBoatData = (data, role) => {
  switch (role) {
    case userRoles.BOAT_OWNER:
    case userRoles.BROKER_AND_DEALER:
    case userRoles.BOAT_MANUFACTURER:
      const { images, boatName: name, address: boatAddress, rating, description } = data
      const {
        commonAddress: { city: boatCity, country: boatCountry },
      } = getAddress(boatAddress)

      return {
        image: (images && images.length > 0 && images[0]?.url) || defaultImage,
        name: name,
        city: boatCity,
        country: boatCountry,
        rating: rating,
        description: description,
        redirection: viewBoatHandler,
      }

    case userRoles.RENT_AND_CHARTER:
      const { images: rentImages, boatName: RentName, address: rentAddress, rentRating, descriptionOfOurTrip } = data
      const {
        commonAddress: { city: rentCity, country: rentCountry },
      } = getAddress(rentAddress)
      return {
        image: rentImages && rentImages.length > 0 && rentImages[0]?.url,
        name: RentName,
        city: rentCity,
        country: rentCountry,
        rating: rentRating,
        description: descriptionOfOurTrip,
        redirection: viewRentBoatHandler,
      }
    case userRoles.SERVICE_AND_MAINTENANCE:
      const {
        images: serviceImages,
        user: { firstName: serviceName },
        address: serviceAddress,
        serviceRating,
        service,
      } = data
      const {
        commonAddress: { city: serviceCity, country: serviceCountry },
      } = getAddress(serviceAddress)
      return {
        image: serviceImages && serviceImages.length > 0 && serviceImages[0]?.url,
        name: serviceName,
        city: serviceCity,
        country: serviceCountry,
        rating: serviceRating,
        description: service,
        redirection: viewServiceBoatHandler,
      }
    case userRoles.MARINA_AND_STORAGE:
      const {
        images: marinaImages,
        user: { companyName },
        address: marinaAddress,
        marinaRating,
        facilities,
      } = data
      const {
        commonAddress: { city: marinaCity, country: marinaCountry },
      } = getAddress(marinaAddress)
      return {
        image: marinaImages && marinaImages.length > 0 && marinaImages[0]?.url,
        name: companyName,
        city: marinaCity,
        country: marinaCountry,
        rating: marinaRating,
        description: facilities,
        redirection: viewMarinaDetails,
      }

    default:
      break
  }
}

//check file type
export const checkFileType = file => {
  const fileData = file.split('.')
  if (imgExtension.includes(fileData[1])) {
    return false
  } else {
    return true
  }
}

export const secretName = (name = '', value = 2, hideFromBegin = true, symbol = '*') => {
  const sliceValue = hideFromBegin ? name.slice(-value) : name.slice(0, value)

  return hideFromBegin ? sliceValue.padStart(name.length, symbol) : sliceValue.padEnd(name.length, symbol)
}

export const getDropdownValue = max => {
  let options = []
  for (let i = 1; i <= max; i++) {
    options.push({ id: `${i}`, value: `${i}` })
  }
  return options
}

export const getSingleSelectValue = value => {
  return {
    id: value,
    value,
  }
}

export const getCurrentRedirection = (path, role, userVerified, profileUpdated, isArticleOrBoatShow) => {
  const updateRouteCheck = updateFormsRoutes.find(route => path.includes(route))

  const pathCheck = updateRouteCheck ? updateRouteCheck : path
  const userVerifiedFlag = JSON.parse(userVerified)
  const profileUpdateFlag = JSON.parse(profileUpdated)

  if (commonRoutes.includes(path)) {
    return profileUpdateFlag && userVerifiedFlag
  }

  switch (role) {
    case userRoles.MEMBER:
      return profileUpdateFlag && withoutDocumentRoutes.includes(pathCheck)

    case userRoles.BOAT_MANUFACTURER:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : userVerifiedFlag && profileUpdateFlag && (boatsRoutes.includes(pathCheck) || dealersRoutes.includes(pathCheck))

    case userRoles.BOAT_OWNER:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : userVerifiedFlag && profileUpdateFlag && boatsRoutes.includes(pathCheck)

    case userRoles.BROKER_AND_DEALER:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : (userVerifiedFlag && profileUpdateFlag && (boatsRoutes.includes(pathCheck) || brokersRoute.includes(pathCheck))) ||
            pathCheck.includes('deals')

    case userRoles.MARINA_AND_STORAGE:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : userVerifiedFlag && profileUpdateFlag && marinaRoutes.includes(pathCheck)

    case userRoles.RENT_AND_CHARTER:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : userVerifiedFlag && profileUpdateFlag && rentRoutes.includes(pathCheck)

    case userRoles.SERVICE_AND_MAINTENANCE:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : userVerifiedFlag && profileUpdateFlag && serviceRoutes.includes(pathCheck)

    case userRoles.SURVEYOR:
      return articleRoutes.includes(pathCheck)
        ? isArticleOrBoatShow
        : userVerifiedFlag && profileUpdateFlag && branchRoutes.includes(pathCheck)

    default:
      return profileUpdateFlag && userVerifiedFlag
  }
}

export const getBannerImage = (type, siteConfigurations) => {
  switch (type) {
    case 'home':
      return siteConfigurations?.buyAndSellBanner?.url
    case 'rent':
      return siteConfigurations?.rentBanner?.url
    case 'boatService':
      return siteConfigurations?.serviceMaintenanceBanner?.url
    case 'marinaStorage':
      return siteConfigurations?.marinaBanner?.url
    case 'boatShow':
      return siteConfigurations?.boatShowBanner?.url
    case 'boatMedia':
      return siteConfigurations?.boatMediaBanner?.url

    default:
      return displayDefaultImage
  }
}
export const getCurrentUrl = () => {
  return encodeURI(window.location.href)
}

export const mileToKilometers = miles => {
  return Math.round(miles / 0.62137).toLocaleString();
}

export const getVideoType = type => {
  const videoType = type.split('/')
  return `.${videoType[1]}`
}

export const getPagesValue = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const values = urlParamsToObject(urlParams)
  return values.page ? +values.page : pagination.PAGE_COUNT
}

export const getBoatTypeName = boat => {
  const boatType = checkKayakType(boat)

  if (boatType) {
    if (boat.kayakType) {
      return boat.kayakType.alias
    } else if (boat.boatType) {
      return boat.boatType.name
    }
  } else {
    return boat?.boatType?.name
  }
}

export const checkKayakType = boat => {
  return boat?.boatType?.name === boatType.kayak
}

export const checkJetSkiType = boat => {
  return boat?.boatType?.name === boatType.jetSki
}

export const checkModuleVerification = (module, errorMessage) => {
  switch (module) {
    case marketTypes.MARINA_AND_STORAGE:
      return errorMessage?.length && errorMessage.find(item => item.message === marketVerifications.marina)

    case marketTypes.BOAT:
      return errorMessage?.length && errorMessage.find(item => item.message === marketVerifications.boat)

    case marketTypes.YACHT_SERVICE:
      return errorMessage?.length && errorMessage.find(item => item.message === marketVerifications.boatService)

    default:
      return false
  }
}

export const verificationRedirection = (path, history) => {
  history && history.push(path)
}

export const auctionBrokerAndDealerRestrictions = (data, user) => {
  const isSellerBrokerAndDealer = user?.role?.aliasName === userRoles.BROKER_AND_DEALER
  const isBrandNewType = data.boatStatus?.alias === boatType.brandNew

  if (isSellerBrokerAndDealer && isBrandNewType) {
    return false
  } else {
    return true
  }
}

export const getUserListingLabel = (user, total = null) => {
  const userRoleAlias = user?.role?.aliasName
  const userName = user?.companyName || user?.firstName

  const isMainPage = total !== null
  const marinaAndSurveyorTitle = isMainPage ? `${total}` : 'other'
  const defaultTitle = isMainPage ? `${total}` : ''

  switch (userRoleAlias) {
    case userRoles.MARINA_AND_STORAGE:
      return `${userName} listed ${marinaAndSurveyorTitle} marina and storage facility`

    case userRoles.SURVEYOR:
      return `${userName}'s ${marinaAndSurveyorTitle} branches`

    default:
      return `${defaultTitle} ${userName}'s listings`
  }
}

export const checkBrokerAccessibility = (boat, sellerRole) => {
  const boatStatus = boat?.boatStatus?.alias
  if (sellerRole === userRoles.BROKER_AND_DEALER) {
    return boatStatus === boatType.brandNew
  } else {
    return true
  }
}

// this can be used for check whether sales-engine needs surveyor process or boat has not multiple quantity
export const isSurveyorAvailable = (boatStatus, sellerRole) => {
  return !(
    sellerRole?.aliasName === userRoles.BOAT_MANUFACTURER ||
    (sellerRole?.aliasName === userRoles.BROKER_AND_DEALER && boatStatus?.alias === boatType.brandNew)
  )
}

export const boatPaymentTotalPrice = salesEngine => {
  const { paymentInformation } = salesEngine

  const subTotalPrice = Number(paymentInformation?.subTotalPrice)
  const paymentTransferFee = Number(paymentInformation?.paymentTransferFee)
  const totalPrice = Number(paymentInformation?.totalPrice)
  return totalPrice
}

export const showAllTitle = (type, total, boatsType = '') => {
  switch (type) {
    case titleEnums.isCategoryWiseBoat:
      return `${total} ${boatsType} in stock for sale direct from the manufacturer in our market`

    case titleEnums.isCityWiseBoat:
      return `Explore ${total} boats listed in ${boatsType}`

    case titleEnums.isBestDeal:
      return `Explore ${total} affordable boats we recommend in AdamSea`

    case titleEnums.isBoatForSale:
      return `${total} boats added by brokers and boat owners for sale`

    case titleEnums.isRecentSellBoat:
      return `${total} boat added recently for sale`

    case titleEnums.isLittleBoat:
      return ` ${total} jet skis, Kayak and Canoe direct from factories and dealers`

    case titleEnums.isFeature:
      return `${total} feature boats for sale in AdamSea`

    case titleEnums.isExploreService:
      return `${boatsType}`

    default:
      return `${boatsType} ${total}`
  }
}

export const createMarkup = text => {
  return { __html: text }
}

export const getPage = location => {
  const page = new URLSearchParams(location.search).has('page') ? new URLSearchParams(location.search).get('page') : 1
  return page
}

export const dashboardIndex = (index, location) => {
  return index + 1 + (getPage(location) * pagination.PAGE_RECORD_LIMIT - pagination.PAGE_RECORD_LIMIT)
}

export const removeSlash = value => {
  return value.includes('/') ? value.replace('/', '') : value
}
export const generateVideoThumbnail = file => {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')

    // this is important
    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)

    video.onloadeddata = () => {
      let ctx = canvas.getContext('2d')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      return resolve(canvas.toDataURL('image/png'))
    }
  })
}

export const limitTextWithEllipsis = text => {
  if(text.length > 59){
    return `${text.slice(0,59)}...`
  } else {
    return text
  }
}

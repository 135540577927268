import { v4 as uuid } from 'uuid'
import axios from 'axios'
import { getLocalStorageItem } from './storageHelper'
import { nameFormatter } from './string'
import { MEDIA_TYPE } from '../util/enums/enums'
import { envConfig } from '../config'
import { toast } from 'react-toastify'
import { graphqlClient } from './graphqlClient'
import { CREATE_META_RECORD, GET_PRESIGNED_URL } from '../graphql/uploadSchema'

// Single Image Upload & Delete
export const handleSingleFileUpload = async (
  file,
  name,
  setFieldValue,
  addMedia,
  mediaName,
  mediaType = MEDIA_TYPE.IMAGE,
  loading = '',
  { maxImageSize } = {}
) => {
  if (file.length) {
    const res = await uploadFile(file, name, mediaType, undefined, { maxImageSize })
    res?.length && setFieldValue([name], { url: res[0]?.url, name: res[0]?.name })
    res?.length &&
      setFieldValue(`addMedia.${name}`, [...addMedia[name], ...res.map(item => ({ ...item, mediaName, mediaType }))])
  }
  loading && loading(false)
}

export const handleSingleFileDelete = async (value, name, setFieldValue, addMedia, deleteIds) => {
  value.id && setFieldValue('removeMedia', [...deleteIds, value.id])

  setFieldValue(
    `addMedia.${name}`,
    addMedia[name]?.filter(item => item.url !== value.url)
  )

  setFieldValue([name], '')
}

export const uploadFile = async (
  file,
  name,
  mediaType,
  multiple = false,
  { maxImageSize = 300 } = {},
  setUploadPerc = null
) => {
  const allFiles = multiple ? file : [file[file.length - 1]]

  if (maxImageSize) {
    const smaller = allFiles.some(f => /^image\//.test(f.type) && f.size / 1024 < maxImageSize)

    if (smaller) {
      toast.error(`Image size must be min. ${maxImageSize} kb`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
      return null
    }
  }

  const invalidFiles = allFiles.some(f => /^image\//.test(f.type) && f.type !== 'image/jpeg' && f.type !== 'image/png')

  if (invalidFiles) {
    toast.error(`Image must be jpeg, jpg or png.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    })
    return null
  }

  const largeVideoFiles = allFiles.some(f => /^video\//.test(f.type) && f.size > 512 * 1024 * 1024)

  if (largeVideoFiles) {
    toast.error(`Video size must not exceed 512 MB.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    })
    return null
  }

  let tag = document.querySelector(`.${name}--loading`)
  if (tag) {
    tag.style.display = 'flex'
  }

  let images = []

  await Promise.all(
    allFiles &&
      allFiles.map(async singleFile => {
        const mimeType = singleFile.type

        try {
          const {
            data: { fileUpload_getPresignedUrl: data_getPresignedUrl },
          } = await graphqlClient.query({
            query: GET_PRESIGNED_URL,
            variables: { input: { type: mediaType, mime: mimeType, name: singleFile.name } },
          })

          const key = data_getPresignedUrl.key
          const presignedUrl = data_getPresignedUrl.url

          const options = {
            headers: {
              'Content-Type': mimeType,
              'Access-Control-Allow-Origin': '*',
            },
            crossDomain: true,
            onUploadProgress: e => {
              const { loaded, total } = e
              const percent = Math.floor((loaded * 100) / total)
              setUploadPerc?.(percent)
            },
          }

          await axios.put(presignedUrl, singleFile, options)

          const res = await graphqlClient.mutate({
            mutation: CREATE_META_RECORD,
            variables: {
              input: {
                type: mediaType,
                mime: mimeType,
                key,
                size: singleFile.size,
              },
            },
          })

          images = [
            ...images,
            {
              _id: res.data.fileUpload_createMetaRecord,
              url: URL.createObjectURL(singleFile),
              key,
              type: mimeType,
              name: singleFile.name,
            },
          ]
        } catch (er) {
          console.error(er)
          toast.error('Cannot upload file!')
        }
      })
  )

  if (tag) {
    tag.style.display = 'none'
  }

  return images
}

export const getUniqueFiles = (file = [], imageFiles = []) => {
  const files = file.reduce(
    (unique, singleFile) => (!unique.map(({ name }) => name)?.includes(singleFile.name) ? [...unique, singleFile] : unique),
    []
  )

  const uniqueFiles =
    files &&
    files.reduce((accm, item) => {
      return !accm.includes(item.name) ? [...accm, item.name] : accm
    }, imageFiles)

  const fileToUpload = files.reduce(
    (uniqueFileToUploaded, item) =>
      !imageFiles.includes(item.name) && !uniqueFileToUploaded.map(({ name }) => name)?.includes(item.name)
        ? [...uniqueFileToUploaded, item]
        : uniqueFileToUploaded,
    []
  )

  return { uniqueFiles, fileToUpload }
}

export const getCroppedImg = (image, crop, fileName, setCroppedImageUrlObject) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        //reject(new Error('Canvas is empty'));
        console.error('Canvas is empty')
        return
      }
      blob.name = fileName
      setCroppedImageUrlObject(blob)
      window.URL.revokeObjectURL(image)
      image = window.URL.createObjectURL(blob)
      resolve(image)
    }, 'image/jpeg')
  })
}

export const getAddMedia = (addMedia = {}) => {
  const combineMedia = Object.values(addMedia).reduce(
    (combineArray, item) => (item.length ? [...combineArray, ...item] : combineArray),
    []
  )

  return combineMedia.length ? combineMedia.map(({ url, ...otherParams }) => otherParams) : []
}

export const uploadAudio = async (file, name, mediaType, blob) => {
  let images = {}
  const mimeType = 'audio/mp3'
  const extension = `${name}.mp3`
  const blob1 = new File([blob], `${uuid()}-videoThumbnail`, { type: 'audio/mp3' })
  return axios
    .get(
      `${envConfig.CHAT_IMAGE_UPLOAD_URL}?filename=${extension}&token=${getLocalStorageItem(
        'token'
      )}&fileType=${mimeType}&mediaType=${mediaType.toLowerCase()}`
    )
    .then(async response => {
      const signedRequest = response.data.signedRequest
      const options = {
        headers: {
          'Content-Type': mimeType,
          'Access-Control-Allow-Origin': '*',
        },
        crossDomain: true,
      }
      return await axios
        .put(signedRequest, blob1, options)
        .then(async result => {
          return (images = {
            // url: `${envConfig.CHAT_AUDIO_UPLOAD_URL}/${name}`,
            key: 'fileKey',
            type: mimeType,
            name: extension,
          })
        })
        .catch(error => {
          console.log(error)
        })
    })
    .catch(error => {
      console.log(error)
    })
}

const getExt = filename => {
  const ext = filename.split('.').pop()
  if (ext == filename) return ''
  return ext
}

export const uploadChatImages = async (allFiles, name, mediaType) => {
  let images = []
  await Promise.all(
    allFiles &&
      allFiles?.map(async singleFile => {
        const mimeType = singleFile.type
        const newName = getExt(singleFile?.name) ? `${uuid()}.${getExt(singleFile?.name)}` : `${uuid()}.png`
        return axios
          .get(
            `${envConfig.CHAT_IMAGE_UPLOAD_URL}?filename=${newName}&token=${getLocalStorageItem(
              'token'
            )}&fileType=${mimeType}&mediaType=${newName.includes('png') ? 'image' : mediaType.toLowerCase()}`,
            {
              // headers: {
              //     "Access-Control-Allow-Origin": "*",
              // },
              //   crossDomain: true
            }
          )
          .then(async response => {
            const signedRequest = response.data.signedRequest
            // const url = response.data.url; // Uploaded File URL
            const options = {
              headers: {
                'Content-Type': mimeType,
                'Access-Control-Allow-Origin': '*',
              },
              crossDomain: true,
            }
            await axios
              .put(signedRequest, singleFile, options)
              .then(async result => {
                images = [
                  ...images,
                  {
                    url: URL.createObjectURL(singleFile),
                    key: 'fileKey',
                    type: mimeType,
                    name: newName,
                  },
                ]
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      })
  )

  return images
}

import { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { numberWithCommas } from '../../util/utilFunctions'
import CurrencyContext from '../../CurrencyContext'
import HeroSearch from '../../containers/home/hero-search'
import ShortSearch from './short-search'

export default function HomeShortSearch() {
  const { pathname, search } = useLocation()

  const { currentCurrency } = useContext(CurrencyContext)

  const [fields, setFields] = useState([])

  const addCommas = useCallback(n => (n ? numberWithCommas(n) : 0), [])

  const convertCurrency = useCallback(
    price => getConvertedPrice(price || 0, currentCurrency, false, false, true),
    [currentCurrency]
  )

  useEffect(() => {
    if (pathname === '/search-boats') {
      const searchParams = new URLSearchParams(search)

      const country = searchParams.get('country')
      const city = searchParams.get('city')
      const address = searchParams.get('address')
      const boatAlias = searchParams.get('boatAlias')
      const minLength = searchParams.get('minLength')
      const maxLength = searchParams.get('maxLength')
      const minPrice = searchParams.get('minPrice')
      const maxPrice = searchParams.get('maxPrice')
      const minYearBuild = searchParams.get('minYearBuild')
      const maxYearBuild = searchParams.get('maxYearBuild')

      const fields = []

      fields.push(country || city ? address || [city, country].filter(Boolean).join(', ') : 'Anywhere')
      fields.push(boatAlias || 'Any Type')
      fields.push(minLength && maxLength ? `${addCommas(minLength)}-${addCommas(maxLength)} ft` : 'Any Length')
      fields.push(
        minPrice && maxPrice
          ? `${addCommas(convertCurrency(minPrice))}-${addCommas(convertCurrency(maxPrice))} ${currency.currentCurrency}`
          : 'Any Price'
      )
      fields.push(minYearBuild && maxYearBuild ? `${minYearBuild}-${maxYearBuild}` : 'Any Year')

      setFields(fields)
    } else {
      setFields(['Anywhere', 'Any Type', 'Any Length', 'Any Price', 'Any Year'])
    }
  }, [pathname, search])

  return <ShortSearch fields={fields} HeroSearch={HeroSearch} />
}

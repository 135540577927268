import React, { Component } from 'react'
import { v4 as uuid } from 'uuid'
import { connect } from 'react-redux'

import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FaAngleDown, FaAngleLeft, FaArrowLeft, FaCaretDown, FaEyeSlash } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowDropleft, IoMdArrowDropleft, IoMdPerson } from 'react-icons/io'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import {
  register,
  clearAuthorizationFlag,
  getUserRoles,
  setCurrentLocation,
  getAllPageInformationByType,
  getUserLocation,
  getCategoriesWiseBanners,
  clearErrorMessageShow,
  convertWishlistsFromSessionToUser,
  requestAgreementId,
  getUserByAgreementId,
  clearAgreementIdData,
  clearAgreementIdDataFlag,
  updateUserDetailByAgreementId,
  assignAgreement,
  getCountryDetails,
} from '../../redux/actions'
import { Loader } from '../../components'
import { Notification, Field } from '../../components'
import {
  userRoles,
  providerOptions,
  latLng,
  AddressInputTypeEnum,
  passwordStrengthEnum,
  footerLinks,
} from '../../util/enums/enums'
import TermAndPolicy from '../../components/termAndPolicy'
import {
  getAgreementRole,
  checkAgreementId,
  validatePassword,
  passwordStrength,
  cityCountryNameFormatter,
} from '../../helpers/jsxHelper'
import { requireMessage, validationMessages, nameFormatter, trimmedValue } from '../../helpers/string'
import ErrorComponent from '../../components/error/errorComponent'
import GoogleMap from '../../components/map/map'
import styles from './register.module.scss'
import '../../styles/common.scss'
import './register.scss'
import '../../styles/registerResponsive.scss'
import { LogInSignupBanner } from '../../components/styleComponent/styleComponent'
import {
  getAllPageInfoByType,
  clearPageInfoByTypeFlag,
  getStaticPages,
  clear_getStaticPages,
} from '../../redux/actions/pageInfoByTypeAction'
import Captcha from '../../components/helper/captcha'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { CommonModal } from '../../components/modal/commonModal'
import PasswordValidation from '../../components/passwordValidations/passwordValidation'
import { ButtonComponent } from '../../components/form/Button'
import { isValidMobileNumber } from '../../helpers/validationHelper'
import { stringValidation } from '../../helpers/yupHelper'
import { getImgUrl } from '../../util/utilFunctions'
import Select from 'react-select'
import { isEmpty } from 'lodash'
import ZohoChatWidget from '../../components/zoho/ZohoChatWidget'
import { CommonTooltip } from '../../components/CommonTooltip'
import { Tooltip } from 'react-tooltip'
import classNames from 'classnames'
import { ArrowLeft } from '@mui/icons-material'
import OtpInput from 'react-otp-input'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newUser: {
        country: '',
        // role: "",
        agreementId: '',
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        password: '',
        address: '',
        roleAlias: '',
        agree: true,
        // hoveredOption : null,
      },

      type: 'password',
      wording: <AiOutlineEye />,

      termsModal: false,
      privacyModal: false,
      errorMessages: {},

      isCheckedAgreementId: null,
      openMapDialog: false,

      isDisplayHint: false,

      addressInput: [],
      formSubmitted: false,

      isUserFound: false,
      isWrongAgreementId: true,
      agreementIdMisMatch: false,
      agreementRoleType: '',
      wrongAgreementId: false,
      isVerifyCaptch: false,
      recaptchaRef: null,
      isDisabled: false,
      isRole: true,
      isMenuOpen: false,

      showGuide: true,
      accTypeDdOpen: false,

      isCountryBlocked: false,

      accRadioMain: null,
    }
    this.selectRef = React.createRef()
  }

  handleMenuOpen = () => {
    this.setState({ showGuide: false, isMenuOpen: true })
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleMouseEnter = () => {
    if (!this.state.isMenuOpen) {
      this.handleMenuOpen()
    }
  }

  handleMouseLeave = () => {
    if (this.state.isMenuOpen) {
      this.handleMenuClose()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      isAuthorized,
      history,
      clearAuthFlag,
      location,
      match,
      currentLocation,
      convertWishlistsFromSessionToUser,
      getByAgreementIdSuccess,
      agreementUser,
      removeData,
      clearAgreementIdDataFlag,
      userVerified,
      clearErrorMessageShow,
      registerSuccess,
      isAgreementUser,
      getAllPageInformationByType,
      getByAgreementIdError,
      roles: allRoles,
      isInline,
      registerType,
    } = nextProps
    const { newUser, isRole, roles } = prevState
    // console.log(nextProps)
    // console.log(prevState)

    if (getByAgreementIdError) {
      newUser.firstName = ''
      newUser.lastName = ''
      newUser.email = ''
      newUser.companyName = ''
      newUser.mobileNumber = ''
      return {
        newUser,
        isUserFound: false,
      }
    }
    if (registerSuccess && isAgreementUser) {
      window.location.href = history.location.pathname + '?agreement-option=yes'
    }
    if (userVerified && agreementUser.role?.id !== newUser.role) {
      return {
        agreementIdMisMatch: true,
      }
    }
    if (isRole && newUser.roleType) {
      getAllPageInformationByType({
        input: { module: newUser.roleAlias },
      })
      return {
        isRole: false,
      }
    }
    if (getByAgreementIdSuccess) {
      clearErrorMessageShow()

      if (agreementUser.role.id === newUser.role) {
        newUser.country = agreementUser.country
        newUser.firstName = agreementUser.firstName
        newUser.lastName = agreementUser.lastName
        newUser.email = agreementUser.email
        newUser.companyName = agreementUser.companyName
        newUser.agreementId = agreementUser.agreementId
        newUser.mobileNumber = agreementUser.mobileNumber
        return {
          newUser,
          isUserFound: true,
          isWrongAgreementId: false,
          isDisabled: true,
        }
      }
    }

    if (removeData) {
      clearAgreementIdDataFlag()
      newUser.firstName = ''
      newUser.lastName = ''
      newUser.email = ''
      newUser.companyName = ''
      newUser.agreementId = ''
      newUser.mobileNumber = ''
      return {
        newUser,
        isUserFound: false,
      }
    }
    if (!newUser.country && currentLocation) {
      newUser.country = currentLocation
      return {
        newUser,
      }
    }
    if (isAuthorized) {
      nextProps.clearAgreementIdData()
      localStorage.setItem('showFirstTimeGuide', 2)
      history.push({ pathname: '/dashboard', state: { showFirstTimeGuide: 2 } })
      clearAuthFlag()
      convertWishlistsFromSessionToUser()
    } else if ((match?.params?.type || isInline) && !isEmpty(roles)) {
      const role = roles.find(item => item.aliasName === (match?.params?.type || registerType))
      const inAllRole = allRoles.find(
        item => item.aliasName === (match?.params?.type || registerType) && item.type !== 'administration'
      )

      if (role) {
        newUser.role = role.id
        newUser.roleType = role.role
        newUser.roleAlias = role.aliasName
        newUser.country = currentLocation

        return {
          newUser,
          isCountryBlocked: false,
          accRadioMain: [userRoles.BOAT_OWNER, userRoles.BOAT_MANUFACTURER, userRoles.BROKER_AND_DEALER].includes(
            newUser.roleAlias
          )
            ? 'SELL'
            : [userRoles.RENT_AND_CHARTER].includes(newUser.roleAlias)
            ? 'RENT'
            : 'OTHER',
        }
      } else if (inAllRole) {
        return { isCountryBlocked: inAllRole.role }
      } else {
        nextProps.history.push('/')
      }
    } else if (roles && roles.length) {
      // const role = roles.find(item => item.aliasName === userRoles.MEMBER)
      // if (role) {
      //   newUser.role = role.id
      //   newUser.roleType = role.role
      //   newUser.roleAlias = role.aliasName
      //   newUser.country = currentLocation
      //   return { newUser }
      // }
    }

    return null
  }

  createMarkup = data => {
    return { __html: data }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isEmpty(this.props.countryDetails) && this.state.newUser.country && !this.state.countryDetailsHit) {
      this.props.getCountryDetails(this.state.newUser.country)
      this.setState({ countryDetailsHit: true })
    }

    if (!isEmpty(this.props.roles) && !isEmpty(this.props.countryDetails) && !this.state.rolesHit) {
      this.setState({
        rolesHit: true,
        roles: this.props.roles.filter(role => {
          if (role.type === 'administration') {
            return false
          }

          const rolesValidMap = {
            [userRoles.MEMBER]: 'mamberStatus',
            [userRoles.AGENT]: 'agentStatus',
            [userRoles.BOAT_OWNER]: 'boatOwerStatus',
            [userRoles.BOAT_MANUFACTURER]: 'boatManufacturerStatus',
            [userRoles.SERVICE_AND_MAINTENANCE]: 'serviceStatus',
            [userRoles.BROKER_AND_DEALER]: 'brokerAndDealerStatus',
            [userRoles.MARINA_AND_STORAGE]: 'marinaStorageStatus',
            [userRoles.RENT_AND_CHARTER]: 'rentStatus',
            [userRoles.YACHT_SHIPPER]: 'ShipperStatus',
            [userRoles.SURVEYOR]: 'surveyorStatus',
          }

          return !this.props.countryDetails[rolesValidMap[role.aliasName]]
        }),
      })
    }

    if (this.props.registerFailure && this.state.formSubmitted) {
      this.setState({
        formSubmitted: false,
      })
    }

    if (this.props.match?.params?.type !== prevProps.match?.params?.type) {
      this.setState({ isCheckedAgreementId: null })
      this.props.clearErrorMessageShow()
      this.props.clearAgreementIdData()
      this.props.clearAgreementIdDataFlag()
    }

    if (this.state.isCheckedAgreementId !== prevState.isCheckedAgreementId) {
      this.props.clearErrorMessageShow()
      this.props.clearAgreementIdData()
      this.props.clearAgreementIdDataFlag()
    }
  }

  componentWillUnmount() {
    const { clearErrorMessageShow, clearAgreementIdData, clearAgreementIdDataFlag } = this.props
    clearErrorMessageShow()
    clearAgreementIdData()
    clearAgreementIdDataFlag()

    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  async componentDidMount() {
    const { getUserLocation, clearErrorMessageShow } = this.props
    const { newUser } = this.state
    const { country } = newUser

    this.props.getStaticPages({ slug: [footerLinks.Terms_Of_Use, footerLinks.Privacy_Policy] })

    clearErrorMessageShow()
    if (!country) {
      // getGeoLocation(this.fetchCountry, true);
      getUserLocation()
    }

    const { getUserRoles, getCategoriesWiseBanners } = this.props
    await getUserRoles()
    const singUpInput = {
      type: 'signUp',
      fieldName: 'signUpBanner',
    }
    await getCategoriesWiseBanners(singUpInput)
    // await getAllPageInformationByType( {input :{}});

    if (this.props.location.search) {
      const searchParams = new URLSearchParams(this.props.location.search)
      const opt = searchParams.get('agreement-option')
      console.log({ opt })

      if (opt === 'yes') {
        this.setState({ isCheckedAgreementId: true })
      }
    }
  }

  // fetchCountry = response => {
  //   const { country } = response;
  //   const { newUser } = this.state;
  //   const { setCurrentLocation } = this.props;

  //   newUser.country = country;
  //   this.setState({ newUser });
  //   setCurrentLocation(country);
  // };

  termsHandler = () => {
    this.setState(prevState => ({
      termsModal: !prevState.termsModal,
    }))
  }

  privacyPolicyHandler = () => {
    this.setState(prevState => ({
      privacyModal: !prevState.privacyModal,
    }))
  }

  changeState = () => {
    const { type } = this.state

    const newType = type === 'password' ? 'text' : 'password'
    const newWord = type === 'password' ? <AiOutlineEyeInvisible /> : <AiOutlineEye />

    this.setState({ type: newType, wording: newWord })
  }

  checkUserRolesForMobile = newUser => {
    const selectedUserRoles = [userRoles.BOAT_MANUFACTURER, userRoles.SURVEYOR, userRoles.YACHT_SHIPPER, userRoles.MEMBER]
    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForCompanyLogo = newUser => {
    const selectedUserRoles = [userRoles.BOAT_MANUFACTURER, userRoles.SURVEYOR, userRoles.YACHT_SHIPPER]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesOfficeLocation = newUser => {
    const selectedUserRoles = [userRoles.SURVEYOR]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForPreference = newUser => {
    const selectedUserRoles = [userRoles.RENT_AND_CHARTER]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForAgreementId = newUser => {
    const selectedUserRoles = [userRoles.BOAT_MANUFACTURER, userRoles.YACHT_SHIPPER, userRoles.SURVEYOR]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForCompanyName = newUser => {
    const selectedUserRoles = [
      userRoles.BROKER_AND_DEALER,
      userRoles.BOAT_MANUFACTURER,
      userRoles.SERVICE_AND_MAINTENANCE,
      userRoles.MARINA_AND_STORAGE,
      userRoles.YACHT_SHIPPER,
      userRoles.SURVEYOR,
    ]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  agreementIdCheckHandler = setFieldValue => {
    const { clearAgreementIdData } = this.props
    const { isCheckedAgreementId } = this.state

    if (isCheckedAgreementId) {
      setFieldValue('agreementId', '')
      clearAgreementIdData(0)
    }
    this.setState(prevState => ({
      isCheckedAgreementId: !prevState.isCheckedAgreementId,
    }))
  }

  fetchMapInfo = (result, setValue) => {
    const { latitude, longitude, ...resultInput } = result

    setValue('address', result.address1)

    this.setState({ latitude, longitude, addressInput: [{ ...resultInput, type: AddressInputTypeEnum.OFFICE_ADDRESS }] })
  }

  mapHandler = () => {
    this.setState(preState => ({ openMapDialog: !preState.openMapDialog }))
  }

  validate = (e, setValue, email = '') => {
    const { errorMessages } = this.state
    const password = e.target.value
    const errors = validatePassword(password, email)

    this.setState({
      errorMessages: errors,
    })

    const pwdStrength = passwordStrength(password, setValue)

    this.setState({
      passwordStrength: pwdStrength,
      isDisplayHint: true,
    })
  }

  getRoleWiseValidations = (newUser, verification) => {
    const afterAgreementValidations = verification
      ? {
          address: Yup.string().required(requireMessage('Address')),
        }
      : {}

    return newUser.roleAlias === userRoles.MEMBER
      ? this.memberValidations()
      : newUser.roleAlias === userRoles.BROKER_AND_DEALER ||
        newUser.roleAlias === userRoles.SERVICE_AND_MAINTENANCE ||
        newUser.roleAlias === userRoles.MARINA_AND_STORAGE
      ? this.brokerValidations()
      : newUser.roleAlias === userRoles.BOAT_MANUFACTURER || newUser.roleAlias === userRoles.YACHT_SHIPPER
      ? { ...this.manufacturerValidations() }
      : newUser.roleAlias === userRoles.SURVEYOR
      ? { ...this.surveyorValidations(), ...afterAgreementValidations }
      : newUser.roleAlias === userRoles.RENT_AND_CHARTER
      ? this.rentAndCharterValidation()
      : {}
  }

  rentAndCharterValidation = () => {
    return {
      provider: Yup.string().required('Please select provider.'),
    }
  }

  memberValidations = () => {
    return {
      mobileNumber: Yup.string()
        .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
        .required(requireMessage('Mobile Number'))
        .ensure(),
    }
  }

  brokerValidations = () => {
    return {
      companyName: stringValidation.required(requireMessage('Company Name')),
    }
  }

  manufacturerValidations = () => {
    return {
      companyName: stringValidation.required(requireMessage('Company Name')),
      mobileNumber: Yup.string()
        .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
        .required(requireMessage('Mobile Number'))
        .ensure(),
    }
  }

  surveyorValidations = () => {
    const rules = this.manufacturerValidations()
    return rules
  }

  agreementValidations = () => {
    return {
      agreementId: stringValidation.required(requireMessage('Code')),
    }
  }

  getUser = agreementId => {
    const { getUserByAgreementId } = this.props
    getUserByAgreementId({ agreementId })
  }

  getUserDetail = values => {
    const userData = {
      agreementId: values.agreementId,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        companyWebsite: values.companyWebsite,
        mobileNumber: values.mobileNumber,
        companyName: values.companyName,
        email: values.email,
        // role: values.role
      },
    }
    return userData
  }

  render() {
    const {
      newUser,
      passwordStrength,
      errorMessages,
      isCheckedAgreementId,
      type,
      wording,
      termsModal,
      privacyModal,
      openMapDialog,
      isDisplayHint,
      latitude,
      longitude,
      formSubmitted,
      addressInput,
      recaptchaRef,
      isVerifyCaptch,
      isDisabled,
    } = this.state
    const {
      register,
      assignAgreement,
      isError,
      errorMessage,
      infoList,
      signUpBanners,
      clearErrorMessageShow,
      infoData,
      loader,
      userVerified,
      clearAgreementIdData,
      agreementUser,
    } = this.props
    const agreement = this.checkUserRolesForAgreementId(newUser)
    const agreementValidations = agreement && isCheckedAgreementId ? this.agreementValidations() : {}
    const agreementRole = newUser && getAgreementRole(newUser.roleAlias)
    const agreementRoleValidation =
      agreementRole && !userVerified
        ? {}
        : {
            password: Yup.string().required(requireMessage('Password')),
          }
    const userVerification = agreementRole
      ? userVerified && agreementUser && agreementUser.role && agreementUser.role.id === newUser.role
      : true
    const roleValidations = this.getRoleWiseValidations(newUser, userVerification)

    const officeAddressDisplay = addressInput?.find(item => item.type === AddressInputTypeEnum.OFFICE_ADDRESS)

    const banner = signUpBanners?.length ? signUpBanners.find(b => b.role === newUser?.roleAlias)?.thumbnail[0] : null

    const roleTooltips = {
      [userRoles.AGENT]: {
        icon: require('./agent.svg'),
        text: `Manage each boat sales transaction by connecting with buyers, sellers, shippers, and facilitating the sales process.`,
      },
      [userRoles.BOAT_MANUFACTURER]: {
        icon: require('./ManufacturerUser.svg'),
        text: `Open your online shop to be a supplier of water toys or any type of boats. Listing is FREE, we manage  shipment and payout for each transaction.`,
      },
      [userRoles.BOAT_OWNER]: {
        icon: require('./BoatOwner.svg'),
        text: `List your boat for free and sell it quickly online; we'll manage the shipment and payout through our secure payment system.`,
      },
      [userRoles.BROKER_AND_DEALER]: {
        icon: require('./Dealer.svg'),
        text: `List all your new and used boats for free, sell them online, and we will handle the shipment and payout for each transaction.`,
      },
      [userRoles.MARINA_AND_STORAGE]: {
        icon: require('./MarinaUser.svg'),
        text: `List your marina and storage with us. Keep adding all your events and boat shows on your page. Connect with boat owners and related users.`,
      },
      [userRoles.MEMBER]: {
        icon: require('./Member.svg'),
        text: `Buy a boat or rent one for a trip, stay connected with other users, list articles, and post updates. Join the marine community.`,
      },
      [userRoles.RENT_AND_CHARTER]: {
        icon: require('./RentUser.svg'),
        text: `Own a kayak or larger boats for rent? List them all for free. We will manage your bookings and ensure payouts for each trip.`,
      },
      [userRoles.SERVICE_AND_MAINTENANCE]: {
        icon: require('./ServiceUser.svg'),
        text: `List your business online, add your services on one page, expand your audience, and stay connected with your customers on AdamSea.`,
      },
      [userRoles.SURVEYOR]: {
        icon: require('./SurveyorUser.svg'),
        text: `Increase your boat survey income by surveying more boats near you, get paid for each job with AdamSea's advance payment system.`,
      },
      [userRoles.YACHT_SHIPPER]: {
        icon: require('./ShipperUser.svg'),
        text: `List your business online, add your services on one page, expand your audience, and stay connected with your customers on AdamSea.`,
      },
    }

    const roleFeatures = {
      [userRoles.AGENT]: [
        'Monitor each boat sold.',
        'Get assigned to manage boats for sale.',
        'Connect with buyers and sellers on AdamSea.',
        'Buy boats online.',
        'Rent trips.',
        'Post articles.',
        'Live chat.',
      ],
      [userRoles.BOAT_MANUFACTURER]: [
        'Free listing.',
        'List all your boat models.',
        'Manage your boat inventory.',
        'Sell your boats online worldwide.',
        'Sell in bulk.',
        'Assign dealers in each country.',
        'Create user and company pages.',
        'Buy boats online',
        'Rent any trip.',
      ],
      [userRoles.BOAT_OWNER]: [
        'Free listings.',
        'Unlimited boat listings.',
        'Sell your boat online.',
        'Create your profile.',
        'Buy boats from others.',
        'Rent any trip',
        'Post and share articles',
        'Live chat and more.',
      ],
      [userRoles.BROKER_AND_DEALER]: [
        'Free listing.',
        'Unlimited boat listings.',
        'Sell your boat online.',
        'Create your profile.',
        'Buy boats from others.',
        'Rent any trip.',
        'Post and share articles.',
        'Live chat and more.',
      ],
      [userRoles.MARINA_AND_STORAGE]: [
        'Create your business page.',
        'List all your marinas and storage in one account.',
        'Create a user profile.',
        'Gain more likes and reviews.',
        'Live chat with your customers.',
        'Buy boats.',
        'Rent trips.',
        'Receive reviews.',
      ],
      [userRoles.MEMBER]: [
        'Buy a boat online from anywhere.',
        'Rent any trip.',
        'Add likes, add reviews.',
        'Create your contact list.',
        'Live chat with your contacts.',
        'Post and share articles',
      ],
      [userRoles.RENT_AND_CHARTER]: [
        'Free and unlimited trip listings.',
        'Post shared trips, private trips or trips per hour.',
        'Online booking system.',
        'Online payment.',
        'Sell your trips as tickets.',
        'Create a user profile and company page.',
        'Buy any boat.',
        'Rent any trip.',
      ],
      [userRoles.SERVICE_AND_MAINTENANCE]: [
        'Create your business page.',
        'List all your services.',
        'Create a user profile.',
        'Live chat with your customers.',
        'Gain more likes and reviews.',
        'Buy boats.',
        'Rent trips.',
      ],
      [userRoles.SURVEYOR]: [
        'Free registration.',
        'Survey boats near you.',
        'Get paid in advance.',
        'Online payment',
        'Connect with buyers and sellers for surveys.',
        'Buy any boat.',
        'Rent trips.',
        'Live chat with other users.',
      ],
      [userRoles.YACHT_SHIPPER]: [
        'Free registration.',
        'Receive delivery requests for each boat sold.',
        'Get paid in advance for each job.',
        'Online payment',
        'Connect with buyers and sellers.',
        'Delivery and shipment processes online.',
        'Create user and company profiles.',
        'Buy boats.',
        'Rent trips',
      ],
    }

    return (
      <>
        <ZohoChatWidget />
        <Notification />
        <Grid
          container
          className={classNames(
            'flex-1 overflow-hidden p-3 login-responsive regi-responsive register-responsive',
            !this.props.isInline && 'h100-vh'
          )}
        >
          {!this.props.isInline && (
            <Grid item className="custom-content-column img-holder register-background-img no-bg-blue" sm={6}>
              <LogInSignupBanner
                // img={banner && encodeURI(getImgUrl(banner))}
                className="h-100 d-flex flex-column"
                style={{ borderRadius: 48 }}
              >
                <div className="flex-1 d-flex flex-column">
                  <div className="website-logo reg-website-logo">
                    <Link to="/">
                      <div className="logo">
                        <img
                          loading="lazy"
                          className="logo-size"
                          src={require('../../assets/images/logo/home.png')}
                          alt="logo"
                        />
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className="mt-5 reg-website-logo">
                      <div className="reg-user-role ml-5 mb-4">
                        {newUser?.roleAlias === userRoles.SERVICE_AND_MAINTENANCE
                          ? 'BOAT SERVICE'
                          : newUser?.roleAlias === userRoles.YACHT_SHIPPER
                          ? 'BOAT SHIPPER'
                          : newUser?.roleType}
                      </div>
                      {roleFeatures[newUser?.roleAlias]?.map(item => (
                        <div className="mt-1 ml-5 reg-user-role-features">&#x2714; {item}</div>
                      ))}
                    </div>

                    {/* <div className="d-flex justify-content-center align-items-start flex-column registerMain-div">
                      <div className="d-flex justify-content-center flex-column">
                        <h3 className="text-white registration-free-text"> Register for free</h3>
                        <div className="d-flex align-self-start flex-column registrationFree-div">
                          <span className="text-white registrationJoin-font website-join-text">
                            Join us today to start selling and
                          </span>
                          <span className="text-left text-white registrationWorldWide-text website-join-text">
                            buying boats online.
                          </span>
                        </div>
                      </div>

                      <div className="d-flex float-left flex-column registration--div">
                        {infoList && infoList.length
                          ? infoList.map((data, index) => (
                              <div
                                key={uuid()}
                                className="d-flex justify-content-start align-items-center registration-infoDiv"
                              >
                                <span>
                                  <span dangerouslySetInnerHTML={{ __html: data.header }} />
                                </span>
                              </div>
                            ))
                          : null}
                      </div>

                      <TermAndPolicy className="register-terms-policy" />
                    </div> */}
                  </div>
                </div>
              </LogInSignupBanner>
            </Grid>
          )}

          <Grid item sm={this.props.isInline ? 12 : 6} className="overflow-auto custom-form-column h-100">
            <Formik
              key={this.props.match?.params?.type}
              initialValues={{ ...newUser }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                firstName: stringValidation.required(requireMessage('First Name')),
                lastName: stringValidation.required(requireMessage('Last Name')),
                email: Yup.string().email('Email is invalid.').required(requireMessage('Email')),
                ...roleValidations,
                ...agreementValidations,
                ...agreementRoleValidation,
              })}
              onSubmit={async values => {
                if (
                  passwordStrength === passwordStrengthEnum.POOR ||
                  passwordStrength === passwordStrengthEnum.WEAK ||
                  errorMessages?.passwordLength ||
                  errorMessages?.symbolOrNumberCount ||
                  errorMessages?.capsCount
                ) {
                  return
                }

                // console.log(values)

                const token = await recaptchaRef.current.execute()
                if (token) {
                  this.setState({ formSubmitted: true })
                  this.setState({ isVerifyCaptch: false })
                  clearErrorMessageShow()
                  const { errorMessages, passwordStrength } = this.state
                  const { agree, roleType, roleAlias, country, mobileNumber, role, ...newValues } = values
                  if (
                    !errorMessages.symbolOrNumberCount &&
                    !errorMessages.passwordLength &&
                    (passwordStrength !== passwordStrengthEnum.POOR || passwordStrength !== passwordStrengthEnum.WEAK)
                  ) {
                    addressInput.push({ type: AddressInputTypeEnum.COMMON_ADDRESS, country: newUser.country })
                    userVerified && assignAgreement({ agreementId: values.agreementId })
                    register({
                      ...newValues,
                      mobileNumber: isValidMobileNumber(mobileNumber, false),
                      // role: newUser.role,
                      userType: newUser.roleAlias,
                      address: addressInput,
                    })
                  }
                } else {
                  this.setState({ isVerifyCaptch: true })
                }
              }}
              render={({ errors, touched, setFieldValue, values, handleSubmit }) => (
                <div className="form-holder register-form-holder h-100">
                  {loader ? (
                    <Loader isSmall isPageLoader />
                  ) : (
                    <div
                      className={classNames(
                        'form-content form-text-fix d-flex flex-column h-100',
                        this.props.isInline && 'p-3'
                      )}
                    >
                      <div className="reg-form-logo">
                        <Link to="/">
                          <img src={require('../../assets/images/logo/home.png')} alt="AdamSea Logo" height={24} />
                        </Link>
                      </div>

                      <h1 className={styles.title}>Create an account</h1>

                      <div className={'form-items ' + styles.form}>
                        {!this.props.isInline && (
                          <>
                            <div className={styles.accRadioPrim}>
                              <button
                                className={this.state.accRadioMain === 'SELL' && styles.active}
                                onClick={() => {
                                  if (this.state.accRadioMain !== 'SELL') {
                                    this.setState({ accRadioMain: 'SELL' })
                                    this.props.history.push(`/register`)
                                    this.setState({
                                      newUser: {
                                        country: '',
                                        agreementId: '',
                                        companyName: '',
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        mobileNumber: '',
                                        password: '',
                                        address: '',
                                        roleAlias: '',
                                        agree: true,
                                      },
                                    })
                                  }
                                }}
                              >
                                Sell your boat
                              </button>
                              <button
                                className={this.state.accRadioMain === 'RENT' && styles.active}
                                onClick={() => {
                                  if (this.state.accRadioMain !== 'RENT') {
                                    this.setState({ accRadioMain: 'RENT' })
                                    this.props.history.push(`/register/${userRoles.RENT_AND_CHARTER}`)
                                    this.setState({
                                      newUser: {
                                        country: '',
                                        agreementId: '',
                                        companyName: '',
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        mobileNumber: '',
                                        password: '',
                                        address: '',
                                        roleAlias: '',
                                        agree: true,
                                      },
                                    })
                                  }
                                }}
                              >
                                Rent out boat
                              </button>
                              <button
                                className={this.state.accRadioMain === 'OTHER' && styles.active}
                                onClick={() => {
                                  if (this.state.accRadioMain !== 'OTHER') {
                                    this.setState({ accRadioMain: 'OTHER' })
                                    this.props.history.push(`/register`)
                                    this.setState({
                                      newUser: {
                                        country: '',
                                        agreementId: '',
                                        companyName: '',
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        mobileNumber: '',
                                        password: '',
                                        address: '',
                                        roleAlias: '',
                                        agree: true,
                                      },
                                    })
                                  }
                                }}
                              >
                                Other
                              </button>
                            </div>

                            <div className={styles.accRadioSec}>
                              {this.state.accRadioMain === 'SELL' && (
                                <>
                                  <button
                                    className={newUser?.roleAlias === userRoles.BOAT_OWNER && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.BOAT_OWNER}`)}
                                  >
                                    Boat Owner
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.BOAT_MANUFACTURER && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.BOAT_MANUFACTURER}`)}
                                  >
                                    Boat Manufacturer
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.BROKER_AND_DEALER && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.BROKER_AND_DEALER}`)}
                                  >
                                    Broker and Dealer
                                  </button>
                                </>
                              )}

                              {this.state.accRadioMain === 'OTHER' && (
                                <>
                                  <button
                                    className={newUser?.roleAlias === userRoles.MEMBER && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.MEMBER}`)}
                                  >
                                    Member
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.MARINA_AND_STORAGE && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.MARINA_AND_STORAGE}`)}
                                  >
                                    Marina and Storage
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.SERVICE_AND_MAINTENANCE && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.SERVICE_AND_MAINTENANCE}`)}
                                  >
                                    Boat Service
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.SURVEYOR && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.SURVEYOR}`)}
                                  >
                                    Surveyor
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.YACHT_SHIPPER && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.YACHT_SHIPPER}`)}
                                  >
                                    Boat Shipper
                                  </button>
                                  <button
                                    className={newUser?.roleAlias === userRoles.AGENT && styles.active}
                                    onClick={() => this.props.history.push(`/register/${userRoles.AGENT}`)}
                                  >
                                    Agent
                                  </button>
                                </>
                              )}
                            </div>

                            {/* <div className={styles.accTypeField}>
                              <button
                                className={styles.accTypeFieldBtn}
                                onClick={() => this.setState(prev => ({ accTypeDdOpen: !prev.accTypeDdOpen }))}
                              >
                                <label className={styles.accTypeFieldBtnLabel}>
                                  {newUser?.roleType
                                    ? 'Register as ' +
                                      (newUser?.roleAlias === userRoles.SERVICE_AND_MAINTENANCE
                                        ? 'Boat Service'
                                        : newUser?.roleAlias === userRoles.YACHT_SHIPPER
                                        ? 'Boat Shipper'
                                        : newUser?.roleType)
                                    : 'Select user to register'}
                                </label>
                                <span className={styles.accTypeFieldIcon}>
                                  <FaAngleDown />
                                </span>
                              </button>

                              {!!this.state.roles?.length && this.state.accTypeDdOpen && (
                                <div
                                  className={styles.accTypeFieldDd}
                                  onMouseLeave={() => this.setState({ accTypeDdOpen: false })}
                                >
                                  <div className={styles.accTypeFieldDdItems}>
                                    {this.state.roles.map(role => (
                                      <div
                                        className={classNames(
                                          styles.accTypeFieldDdItem,
                                          newUser?.roleAlias === role.aliasName && styles.accTypeFieldDdItemActive
                                        )}
                                      >
                                        <button
                                          className={styles.accTypeFieldDdItemBtn}
                                          onClick={() => {
                                            this.props.history.push(`/register/${role.aliasName}`)
                                            this.setState({ accTypeDdOpen: false })
                                          }}
                                          onMouseOver={() => this.setState({ hoveredRole: role.aliasName })}
                                          onMouseOut={() => this.setState({ hoveredRole: null })}
                                        >
                                          {String(
                                            role.aliasName === userRoles.SERVICE_AND_MAINTENANCE
                                              ? 'Boat Service'
                                              : role.aliasName === userRoles.YACHT_SHIPPER
                                              ? 'Boat Shipper'
                                              : role.role
                                          ).toLowerCase()}
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div> */}
                          </>
                        )}

                        {userVerified && agreementUser && newUser && newUser.roleType !== agreementUser.role?.role && (
                          <div className="mt-4 agreement--id--for--error">
                            <p
                              className={`${
                                newUser.roleType !== agreementUser.role?.role ? 'alert-danger' : 'alert-success'
                              } mb-0`}
                            >
                              {newUser.roleType !== agreementUser.role?.role ? <ErrorIcon /> : <CheckCircleOutlineIcon />} This
                              code is for{' '}
                              {agreementUser?.role?.aliasName === userRoles.YACHT_SHIPPER
                                ? 'boat shipper'
                                : agreementUser.role.role?.toLowerCase()}
                            </p>
                          </div>
                        )}

                        {/* <p style={{ position: 'relative', zIndex: 999999999 }}>
                          {!!this.state.roles?.length && (
                            <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} ref={this.selectRef}>
                              <Select
                                value={{
                                  label:
                                    (newUser?.roleAlias === 'service-and-maintenance' ? 'BOAT SERVICE' : newUser?.roleType) ||
                                    'Choose account type',
                                  value: newUser?.roleAlias,
                                }}
                                key={this.state.roles?.length}
                                options={this.state.roles.map(role => ({
                                  label: (
                                    <div>
                                      <div
                                        style={{
                                          color: '#000',
                                          fontWeight: 'bold',
                                          fontSize: '1.1em',
                                        }}
                                      >
                                        {role.aliasName === 'service-and-maintenance' ? 'BOAT SERVICE' : role.role}{' '}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: '0.5em',
                                          fontWeight: 'normal',
                                          fontSize: 16,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {roleTooltips[role.aliasName]}
                                      </div>
                                    </div>
                                  ),
                                  value: role.aliasName,
                                }))}
                                onChange={selected => {
                                  this.props.history.push({
                                    pathname: `/register/${selected?.value}`,
                                  })
                                }}
                                menuIsOpen={this.state.isMenuOpen}
                                onMenuOpen={this.handleMenuOpen}
                                onMenuClose={this.handleMenuClose}
                                autoFocus={false}
                                ref={this.selectRef}
                                styles={{
                                  control: baseStyles => ({
                                    ...baseStyles,
                                    borderRadius: 50,
                                  }),
                                  valueContainer: baseStyles => ({
                                    ...baseStyles,
                                    paddingLeft: 15,
                                  }),
                                  indicatorSeparator: baseStyles => ({
                                    ...baseStyles,
                                    display: 'none',
                                  }),
                                  menu: baseStyles => ({
                                    ...baseStyles,
                                    overflow: 'hidden',
                                    borderRadius: 15,
                                    marginTop: '0rem',
                                  }),
                                }}
                              />
                            </div>
                          )}
                        </p> */}
                        {/* <CommonTooltip
                          className="common-tooltip-guide"
                          place="bottom"
                          key={Math.random()}
                          isOpen={this.state.showGuide}
                          clickable
                          closeOnScroll={false}
                          closeOnResize={false}
                          content={
                            <>
                              <div style={{ textAlign: 'center' }}>Select the user you want to register.</div>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className="btn common-tooltip-button"
                                  onClick={() => this.setState({ showGuide: false })}
                                >
                                  Got it
                                </button>
                              </div>
                            </>
                          }
                          component={ctProps => <div data-tooltip-id={ctProps.id} data-tooltip-place="bottom"></div>}
                        /> */}
                        {newUser?.role && (
                          <Form>
                            <ErrorFocus />
                            <Grid container spacing={2} className="register-input-field">
                              {this.checkUserRolesForAgreementId(newUser) && (
                                <div className="agreement-checkbox-shadow">
                                  <Grid item xs={12}>
                                    <div>
                                      {isCheckedAgreementId === null && (
                                        <label for="agreement" className="agreement-label text-center mb-4">
                                          You need to generate a code to activate your{' '}
                                          {newUser?.roleAlias === userRoles.YACHT_SHIPPER
                                            ? 'boat shipper'
                                            : newUser?.roleType?.toLowerCase()}{' '}
                                          account. Do you have one?
                                        </label>
                                      )}
                                      {/* <input
                                        id="agreement"
                                        className="mb-0"
                                        type="checkbox"
                                        value={isCheckedAgreementId}
                                        onChange={() => this.agreementIdCheckHandler(setFieldValue)}
                                        checked={isCheckedAgreementId}
                                      /> */}
                                      <div className="d-flex justify-content-center align-items-center text-center">
                                        <div>
                                          <ButtonComponent
                                            className={classNames(
                                              'btn-o-black btn-large justify-content-center flex-column rounded-full',
                                              { 'btn-o-black-active': isCheckedAgreementId === true },
                                              styles.txtTNone
                                            )}
                                            onClick={() => this.agreementIdCheckHandler(setFieldValue)}
                                          >
                                            {!userVerified || newUser.roleType !== agreementUser?.role?.role ? (
                                              'Yes, activate it'
                                            ) : (
                                              <>Code verified &#x2714;</>
                                            )}
                                            {/* <div className="mt-1 font-16"></div> */}
                                          </ButtonComponent>
                                        </div>
                                        <div className="ml-2">or</div>
                                        <div className="ml-2">
                                          <ButtonComponent
                                            className={classNames(
                                              'btn-o-black btn-large justify-content-center flex-column rounded-full',
                                              { 'btn-o-black-active': isCheckedAgreementId === false },
                                              styles.txtTNone
                                            )}
                                            onClick={() => {
                                              this.setState(prevState => ({ isCheckedAgreementId: false }))
                                              setFieldValue('agreementId', '')
                                            }}
                                          >
                                            Generate a code
                                            {/* <div className="mt-1 font-16">Request one</div> */}
                                          </ButtonComponent>
                                        </div>
                                      </div>
                                    </div>
                                    {/* {!isCheckedAgreementId && (
                                      <div className="agreement-label mt-3">If not, fill out the form to generate one.</div>
                                    )} */}
                                  </Grid>
                                  {isCheckedAgreementId && !this.state.isUserFound && (
                                    <Grid item xs={12}>
                                      <div className={`${isCheckedAgreementId ? 'agreement--input' : ''} position-relative`}>
                                        <OtpInput
                                          className="mt-1 custom-otpInputCont"
                                          numInputs={6}
                                          shouldAutoFocus
                                          value={values.agreementId}
                                          onChange={value => {
                                            value = trimmedValue(value)
                                            const agreementIdValidate = checkAgreementId(value)
                                            if (agreementIdValidate) {
                                              setTimeout(() => {
                                                this.getUser(value)
                                              }, 300)
                                            } else {
                                              clearAgreementIdData()
                                            }
                                            setFieldValue('agreementId', value)
                                          }}
                                        />
                                        {/* <input
                                          name="agreementId"
                                          id="agreementId"
                                          type="text"
                                          value={values.agreementId}
                                          className="form-control font-16"
                                          placeholder="Agreement ID"
                                          // disabled={userVerified}
                                          onChange={e => {
                                            const value = trimmedValue(e.target.value)

                                            const agreementIdValidate = checkAgreementId(value)
                                            if (agreementIdValidate) {
                                              this.getUser(value)
                                            } else {
                                              clearAgreementIdData()
                                            }
                                            setFieldValue('agreementId', value)
                                          }}
                                          required
                                        ></input> */}
                                        <span className="form-side-icon reg-side-icon">{/* <MdEmail /> */}</span>
                                        <ErrorMessage component="div" name="agreementId" className="error-message" />
                                      </div>
                                    </Grid>
                                  )}
                                </div>
                              )}
                              {(this.checkUserRolesForAgreementId(newUser)
                                ? isCheckedAgreementId !== null && (isCheckedAgreementId ? this.state.isUserFound : true)
                                : true) && (
                                <>
                                  {this.checkUserRolesForPreference(newUser) && (
                                    <Grid item xs={12}>
                                      <div className="reg-radios">
                                        {providerOptions.map(p => (
                                          <label className={`reg-radio ${values.provider === p.id ? 'reg-radio-checked' : ''}`}>
                                            {p.value}

                                            <input
                                              type="radio"
                                              id={`provider-${p.id}`}
                                              name="provider"
                                              checked={values.provider === p.id}
                                              onChange={e => e.target.checked && setFieldValue('provider', p.id)}
                                            />
                                          </label>
                                        ))}
                                      </div>

                                      {errors.provider && <div className="invalid--feedback--error">{errors.provider}</div>}
                                    </Grid>
                                  )}

                                  {this.checkUserRolesForCompanyName(newUser) && (
                                    <Grid item xs={12}>
                                      <div className="position-relative">
                                        <input
                                          name="companyName"
                                          id="companyName"
                                          type="text"
                                          value={values.companyName}
                                          className="form-control font-16"
                                          placeholder="Company Name"
                                          onChange={e =>
                                            e.target.value?.length <= 20 && setFieldValue('companyName', e.target.value)
                                          }
                                          onBlur={e => setFieldValue('companyName', trimmedValue(e.target.value))}
                                          required
                                        ></input>
                                        <span className="form-side-icon reg-side-icon">{/* <MdEmail /> */}</span>
                                        <ErrorMessage component="div" name="companyName" className="error-message" />
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <div className="position-relative">
                                      <input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        value={values.firstName}
                                        className="form-control font-16"
                                        placeholder="First Name"
                                        onChange={e => setFieldValue('firstName', e.target.value)}
                                        onBlur={e => setFieldValue('firstName', trimmedValue(e.target.value))}
                                        required
                                      ></input>
                                      {/* <span className="form-side-icon reg-side-icon icon-top-space">
                                  <IoMdPerson />
                                </span> */}
                                      <ErrorMessage component="div" name="firstName" className="error-message" />
                                    </div>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <div className="position-relative">
                                      <input
                                        id="lastName"
                                        name="lastName"
                                        value={values.lastName}
                                        type="text"
                                        className="form-control font-16"
                                        placeholder="Last Name"
                                        onChange={e => setFieldValue('lastName', e.target.value)}
                                        onBlur={e => setFieldValue('lastName', trimmedValue(e.target.value))}
                                        required
                                      ></input>
                                      {/* <span className="form-side-icon reg-side-icon icon-top-space">
                                  <IoMdPerson />
                                </span> */}
                                      <ErrorMessage component="div" name="lastName" className="error-message" />
                                    </div>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <div className="position-relative">
                                      <div className="mail-icon ">
                                        <input
                                          autocomplete="random"
                                          name="email"
                                          id="email"
                                          type="text"
                                          value={values.email}
                                          disabled={isDisabled}
                                          className="form-control font-16"
                                          placeholder="Email Address"
                                          onChange={e => setFieldValue('email', trimmedValue(e.target.value))}
                                          required
                                        />
                                        {/* <span className="form-side-icon reg-side-icon icon-email">
                                    <MailOutlineIcon />
                                  </span> */}
                                      </div>
                                      <ErrorMessage component="div" name="email" className="error-message" />
                                    </div>
                                  </Grid>

                                  {this.checkUserRolesForMobile(newUser) && (
                                    <Grid item xs={12} className="phone-number">
                                      <Field
                                        className="mobile-placeholder"
                                        type="mobile-number"
                                        placeholder="Mobile Number"
                                        id="mobileNumber"
                                        disabled={isDisabled}
                                        name="mobileNumber"
                                        value={values.mobileNumber}
                                        onChangeText={value => setFieldValue('mobileNumber', value)}
                                        noFormControl
                                      />
                                      <ErrorMessage component="div" name="mobileNumber" className="error-message" />
                                      <Dialog>
                                        <DialogContent className="google--map--position">
                                          <GoogleMap
                                            className="googleMap-position"
                                            isFullGrid
                                            latLng={
                                              values.address
                                                ? {
                                                    lat: latitude,
                                                    lng: longitude,
                                                  }
                                                : latLng
                                            }
                                            fetch={result => this.fetchMapInfo(result, setFieldValue)}
                                            height={30}
                                            width={100}
                                            placeHolder="find your office location"
                                            columnName={'address'}
                                            isError={errors.address}
                                            onClose={this.mapHandler}
                                            isCloseBtn
                                            isRegister
                                            isUpdate={values.address ? true : false}
                                          ></GoogleMap>
                                        </DialogContent>
                                      </Dialog>
                                    </Grid>
                                  )}

                                  {this.checkUserRolesOfficeLocation(newUser) &&
                                    userVerified &&
                                    agreementUser &&
                                    agreementUser.role.id === newUser.role && (
                                      <Grid item xs={12} className="register-map-main-div">
                                        <button type="button" onClick={this.mapHandler}>
                                          Add Your Office Location
                                        </button>

                                        {values.address && (
                                          <div className="register-map-msg-display">
                                            {cityCountryNameFormatter(
                                              officeAddressDisplay?.city,
                                              officeAddressDisplay?.country
                                            )}
                                          </div>
                                        )}
                                        <ErrorMessage component="div" name="address" className="error-message" />
                                        <Dialog
                                          open={openMapDialog}
                                          onClose={this.mapHandler}
                                          classes={{ container: 'model-width register-map-custom-width' }}
                                          aria-labelledby="responsive-dialog-title "
                                        >
                                          <DialogContent className="overflow-hidden register--map--content--dialog">
                                            <GoogleMap
                                              className="googleMap-position"
                                              isFullGrid
                                              latLng={
                                                values.address
                                                  ? {
                                                      lat: latitude,
                                                      lng: longitude,
                                                    }
                                                  : latLng
                                              }
                                              fetch={result => this.fetchMapInfo(result, setFieldValue)}
                                              height={30}
                                              width={100}
                                              placeHolder="find your office location"
                                              columnName={'address'}
                                              isError={errors.address && touched.address}
                                              onClose={this.mapHandler}
                                              isCloseBtn
                                              isRegister
                                              isUpdate={values.address}
                                            ></GoogleMap>
                                            <div className="map--submit-cancel-register">
                                              <ButtonComponent className="map--submit-btn" onClick={this.mapHandler}>
                                                Submit
                                              </ButtonComponent>
                                              <ButtonComponent
                                                color="cancel"
                                                className="map--cancel-btn"
                                                onClick={this.mapHandler}
                                              >
                                                Cancel
                                              </ButtonComponent>
                                            </div>
                                          </DialogContent>
                                        </Dialog>
                                      </Grid>
                                    )}

                                  <Grid item xs={12}>
                                    {((agreementRole && userVerification) || !agreementRole) && (
                                      <div className="position-relative register-field">
                                        <div className="position-relative">
                                          <input
                                            autocomplete="random"
                                            id="password"
                                            name="password"
                                            type={type}
                                            className={'form-control font-16 password--spacing'}
                                            placeholder="Password"
                                            onChange={e => {
                                              setFieldValue('password', e.target.value)
                                              this.validate(e, setFieldValue, values.email)
                                            }}
                                            required
                                          ></input>
                                          <span className="form-side-icon reg-side-icon" onClick={this.changeState}>
                                            {wording}
                                          </span>
                                        </div>
                                        <ErrorMessage component="div" name="password" className="invalid--feedback--error" />
                                      </div>
                                    )}
                                    {((agreementRole && userVerification) || !agreementRole) && isDisplayHint && (
                                      <PasswordValidation errorMessages={errorMessages} passwordStrength={passwordStrength} />
                                    )}
                                  </Grid>
                                </>
                              )}

                              <Grid item xs={12}>
                                <Captcha getRefValue={value => this.setState({ recaptchaRef: value })} />
                              </Grid>
                            </Grid>

                            {(this.checkUserRolesForAgreementId(newUser)
                              ? isCheckedAgreementId !== null && (isCheckedAgreementId ? this.state.isUserFound : true)
                              : true) && (
                              <>
                                <ButtonComponent
                                  variant="outline"
                                  color="success"
                                  className={
                                    'h-auto btn-black w-100 justify-content-center login--sign--up--btn rounded-full ' +
                                    styles.txtTNone
                                  }
                                  disabled={formSubmitted}
                                  loader={formSubmitted}
                                  onClick={handleSubmit}
                                >
                                  {!this.checkUserRolesForAgreementId(newUser) || this.state.isCheckedAgreementId
                                    ? 'Create account'
                                    : 'Send me a code'}
                                </ButtonComponent>
                              </>
                            )}
                            {isVerifyCaptch && <div className="alert alert-danger">Invalid captach</div>}
                            {this.checkUserRolesForAgreementId(newUser) && isCheckedAgreementId && (
                              <div className="d-flex">
                                <div>
                                  <img src={require('../../assets/images/megaphone_9551884.svg')} height={20} />
                                </div>
                                <div className="ml-3 flex-1 font-14">
                                  When you generate a code, you'll receive it via email and SMS. User in the USA will receive it
                                  by email only.
                                </div>
                              </div>
                            )}
                            {(this.checkUserRolesForAgreementId(newUser) ? newUser.agreementId : true) && (
                              <div>
                                <label className="mt-4" for="remember me">
                                  <span className="term-span-register color-black">
                                    <span className="Normal-Font">By proceeding, you agree to the </span>
                                    <div
                                      onClick={this.termsHandler}
                                      className="ml-1 Normal-Font darkBlue mr-1 underline--on--hover"
                                    >
                                      Terms and Conditions
                                    </div>
                                    <span className="Normal-Font">and</span>
                                    <div
                                      onClick={this.privacyPolicyHandler}
                                      className="ml-1 Normal-Font darkBlue underline--on--hover"
                                    >
                                      Privacy Policy
                                    </div>
                                    .
                                  </span>
                                </label>
                              </div>
                            )}
                            <CommonModal
                              open={termsModal}
                              title={this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Terms_Of_Use)?.title}
                              className="modal--bg--transparent term--policy"
                              close={this.termsHandler}
                            >
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Terms_Of_Use)?.pageContent
                                )}
                              />
                            </CommonModal>
                            <CommonModal
                              open={privacyModal}
                              title={this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Privacy_Policy)?.title}
                              className="modal--bg--transparent term--policy"
                              close={this.privacyPolicyHandler}
                            >
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Privacy_Policy)
                                    ?.pageContent
                                )}
                              />
                            </CommonModal>
                          </Form>
                        )}
                        {/* {console.log(isError)} */}
                        {/* {console.log(errorMessage)} */}
                        {isError && errorMessage && errorMessage.length ? <ErrorComponent errors={errorMessage} /> : <> </>}

                        {(this.checkUserRolesForAgreementId(newUser) ? newUser.agreementId : true) && (
                          <div className="page-links" style={{ marginTop: 'auto' }}>
                            <p className="Normal-Font">
                              Already have an AdamSea account?
                              <Link to="/login" className="font-weight-500">
                                {' Log in'}
                              </Link>
                            </p>
                          </div>
                        )}

                        {this.state.accTypeDdOpen && roleTooltips[this.state.hoveredRole || '_'] && (
                          <div className="login-tooltip-bottom-box">
                            {roleTooltips[this.state.hoveredRole].icon && (
                              <img src={roleTooltips[this.state.hoveredRole].icon} alt={this.state.hoveredRole} />
                            )}
                            {roleTooltips[this.state.hoveredRole].text}
                          </div>
                        )}

                        {/* <div className="other-links register-website-link ">
                          <span className="Normal-Font">
                            Ensure that whenever you sign in to AdamSea, the Web address in your browser starts with
                          </span>
                          <a target="_blank" href="https://adamsea.com/" className="darkBlue link Normal-Font">
                            www.adamsea.com
                          </a>
                        </div> */}
                      </div>

                      <div className="reg-form-back-btn">
                        <Link to="/">
                          <FaAngleLeft /> Back to market
                        </Link>
                      </div>

                      {this.state.isCountryBlocked && (
                        <div style={{ marginTop: 'auto', padding: '16px 24px', borderRadius: 8, border: '1px solid #0002' }}>
                          <div className="d-flex">
                            <div style={{ flexShrink: 0 }}>
                              <img
                                src={require('../../assets/images/megaphone_9551884.svg')}
                                alt="bell"
                                height={28}
                                style={{ height: 28 }}
                              />
                            </div>
                            <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }}>
                              We're sorry, registration for {this.state.isCountryBlocked} users is not available in{' '}
                              {this.props.currentLocation} at this time.
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isError: state.loginReducer.isError,
  isAuthorized: state.loginReducer.isAuthorized,
  errorMessage: state.errorReducer.errorMessage,
  roles: state.loginReducer.roles,
  currentLocation: state.loginReducer.currentLocation,
  infoList: state.loginReducer?.infoList,
  agreementUser: state.loginReducer?.agreementUser,
  userVerified: state.loginReducer?.userVerified,
  removeData: state.loginReducer?.removeData,
  requestAgreementIdSuccess: state.loginReducer?.requestAgreementIdSuccess,
  loader: state.loginReducer?.isLoading,
  getByAgreementIdSuccess: state.loginReducer?.getByAgreementIdSuccess,
  getByAgreementIdError: state.loginReducer?.getByAgreementIdError,
  signUpBanners: state.bannerReducer && state.bannerReducer['signUpBanner'],
  infoData: state.pageInfoByTypeReducer?.pageInfoByType,
  isAgreementUser: state.loginReducer?.isAgreementUser,
  registerSuccess: state.loginReducer?.registerSuccess,
  registerFailure: state.loginReducer?.registerFailure,
  staticPages: state.pageInfoByTypeReducer.staticPages,
  countryDetails: state.dashboardReducer.countryDetails,
})

const mapDispatchToProps = dispatch => ({
  register: data => dispatch(register(data)),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  getUserRoles: () => dispatch(getUserRoles()),
  setCurrentLocation: data => dispatch(setCurrentLocation(data)),
  getUserLocation: data => dispatch(getUserLocation(data)),
  getAllPageInformationByType: data => dispatch(getAllPageInformationByType(data)),
  getCategoriesWiseBanners: data => dispatch(getCategoriesWiseBanners(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  clearPageInfoByTypeFlag: data => dispatch(clearPageInfoByTypeFlag()),
  convertWishlistsFromSessionToUser: () => dispatch(convertWishlistsFromSessionToUser()),
  requestAgreementId: data => dispatch(requestAgreementId(data)),
  getUserByAgreementId: data => dispatch(getUserByAgreementId(data)),
  clearAgreementIdData: () => dispatch(clearAgreementIdData()),
  clearAgreementIdDataFlag: () => dispatch(clearAgreementIdDataFlag()),
  updateUserDetailByAgreementId: data => dispatch(updateUserDetailByAgreementId(data)),
  assignAgreement: data => dispatch(assignAgreement(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
  getCountryDetails: data => dispatch(getCountryDetails(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)

import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { GoogleMap as Map, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api'
import { Grid, Card, CardContent } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import './map.scss'
import { MapPlaceInfoStyle, MapPlaceImgStyle } from '../styleComponent/styleComponent'
import { getConvertedPrice, searchBy } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import RatingComponent from '../rating/Rating'
import { cityCountryNameFormatter } from '../../helpers/jsxHelper'
import { highlightedIconForMap } from '../../util/enums/enums'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { Field } from '../ws/Field'
import { envConfig } from '../../config'
import { commonMarinaType } from '../../util/utilFunctions'
import { googleDirectionUrl } from '../../helpers/boatHelper'

const GoogleMarker = props => {
  const {
    markers,
    isMultiple,
    isInnerPage,
    geometricLocation,
    isRedirectToMap,
    gotoDirectionText,
    isNotChat = true,
    zoomControlPos,
  } = props

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: envConfig.MAP_API_KEY,
    libraries: ['places'],
  })

  const [mapStyle, setMapStyle] = useState({
    width: props.width ? `${props.width}vh` : '100%',
    height: props.height ? `${props.height}vh` : '100%',
  })

  const [isIdleActive, setIsIdleActive] = useState(false)

  const [bounds, setBounds] = useState(null)
  const [getCenter, setCenter] = useState(null)

  const [searchAsMapMove, setSearchAsMapMove] = useState(true)
  const [searchThisArea, setSearchThisArea] = useState(false)
  const [mapBounds, setMapBounds] = useState(null)

  useEffect(() => {
    if (isLoaded) {
      const distMarkers = []

      if (markers.length === 2 && JSON.stringify(markers[0].position) === JSON.stringify(markers[1].position)) {
        distMarkers.push(markers[0])
      } else {
        distMarkers.push(...markers)
      }

      if (distMarkers.length > 1) {
        if (!bounds) {
          const bounds = new google.maps.LatLngBounds()
          distMarkers.forEach(marker => {
            bounds.extend(marker.position)
          })
          setBounds(bounds)
          setCenter(distMarkers[0].position)
        }
      } else if (distMarkers.length === 1) {
        if (!getCenter) {
          setCenter(distMarkers[0].position)
        }
      }
    }
  }, [isLoaded, markers])

  const [info, setInfo] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    marker: {},
  })

  const onMarkerClick = (props, marker, e, markerValue) => {
    setInfo({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      marker: markerValue,
    })
  }

  const onMapClick = () => {
    setInfo({
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      marker: {},
    })
  }

  const onIdle = (mapProps, map) => {
    if (isIdleActive) {
      const bounds = map.getBounds()
      const ne = bounds?.getNorthEast() // LatLng of the north-east corner
      const sw = bounds?.getSouthWest()

      const mapBoundObject = {
        northEast: [ne.lng(), ne.lat()],
        southWest: [sw.lng(), sw.lat()],
      }
      setMapBounds(mapBoundObject)

      searchAsMapMove && JSON.stringify(mapBoundObject) !== JSON.stringify(mapBounds)
        ? ne && sw && props.fetchResultOnMapChange(mapBoundObject)
        : setSearchThisArea(true)
    } else {
      setIsIdleActive(true)
    }
  }

  const handleSearchArea = () => {
    mapBounds && props.fetchResultOnMapChange(mapBounds)
    setSearchThisArea(false)
  }

  const displayMarkers = useCallback(() => {
    return (
      markers &&
      markers.length > 0 &&
      markers.map(markerValue => {
        return (
          markerValue && (
            <Marker
              onClick={(e) => onMarkerClick(props, markerValue?.position, e, markerValue)}
              key={markerValue.id}
              // title={markerValue.title} [NOTE]: Need to discuss with jayesh and ashutosh QA (NOT remove it)
              position={markerValue.position}
              icon={{
                anchor: new google.maps.Point(32, 32),
                scaledSize: new google.maps.Size(40, 40),
                url: props.highlightBoatId === markerValue.id ? highlightedIconForMap : markerValue.icon.url,
              }}
            />
          )
        )
      })
    )
  }, [markers])

  const getNewWidth = () => {
    const width = document.querySelector('body')
    const actualWidth = width && width.offsetWidth / 6.7
    return actualWidth
  }

  const getNewHeight = () => {
    const width = document.querySelector('body')
    const actualWidth = width && width.offsetWidth / 9
    return actualWidth
  }

  const markerInfoWindow = () => {
    if (!info.activeMarker?.lat || !info.activeMarker?.lng || !info.showingInfoWindow) return

    return (
      <InfoWindow position={info.activeMarker} marker={info.activeMarker} onOpen={e => onInfoWindowOpen(e)}>
        <div id={`info-window-container-${info.marker.id}`}></div>
      </InfoWindow>
    )
  }

  const onInfoWindowOpen = e => {
    // infoElement.remove()

    const element = infoWindowContainer()
    ReactDOM.render(element, document.getElementById(`info-window-container-${info.marker.id}`))
  }

  const redirect = () => window && info?.marker?.redirectUrl && window.open(info.marker.redirectUrl, '_blank')

  const infoWindowContainer = () => (
    <MapPlaceInfoStyle className="map--market-img-div" bgWidth={getNewWidth}>
      {info.marker.image && isInnerPage && (
        <MapPlaceImgStyle bgHeight={getNewHeight}>
          <img loading="lazy" src={info.marker.image} alt="logo" className="h-100 w-100 map-market-img" onClick={redirect} />
        </MapPlaceImgStyle>
      )}
      {info.marker.provider && (
        <div className=" pl-3 pt-3 pr-3 marina--type--dev marina--type--name">{commonMarinaType(info.marker.provider)}</div>
      )}

      <div
        className={`${info.marker.provider && 'responsive--map--spacing'} d-flex flex-column p-3 responsive-map-content`}
        onClick={redirect}
      >
        {info.marker.facilityName && (
          <span className="map-info-title-section title-section-top mb-1">{info.marker.facilityName}</span>
        )}
        {info.marker.contactName && (
          <span className="map-info-title-section title-section-top-1 mb-1">{info.marker.contactName}</span>
        )}

        {!info.marker.contactName && (
          <span className="map-info-desc-section title-section-desc-1 mb-1">
            {cityCountryNameFormatter(info.marker.city, info.marker.country, ' • ')}
          </span>
        )}

        {info.marker?.title && <span className="map-info-title-section title-section-desc-2 mb-1">{info.marker.title}</span>}

        {info.marker?.post && (
          <span className="map-info-service-discription-section title-section-desc-3 mb-1">Position: {info.marker.post}</span>
        )}

        {info.marker.contactName && (
          <span className="map-info-desc-section title-section-desc-4 mb-1">
            {cityCountryNameFormatter(info.marker.city, info.marker.country, ' • ')}
          </span>
        )}

        {info.marker?.companyName && <span className="map-info-company-section mb-1">By {info.marker.companyName}</span>}

        {info.marker?.description && (
          <span className="map-info-service-discription-section mb-1">{info.marker.description}</span>
        )}

        {info.marker.rating > 0 && (
          <div className="d-flex align-items-center mb-1">
            <RatingComponent rating={info.marker.rating} className="rating-clr mr-2" />
            <h6 className="rating-avg mb-0 pt-1">({(+info.marker.rating).toFixed(1)})</h6>
          </div>
        )}

        <CurrencyContextConsumer>
          {({ currentCurrency }) => (
            <>
              {!!info.marker.price && (
                <span className="map-info-price-section">{getConvertedPrice(info.marker.price, currentCurrency)}</span>
              )}
              {/* {info.marker.pricePerFt && (
                <span className="map-info-price-section">
                  Price/ft: {getConvertedPrice(info.marker.pricePerFt, currentCurrency)}
                </span>
              )} */}
            </>
          )}
        </CurrencyContextConsumer>
      </div>
    </MapPlaceInfoStyle>
  )

  const geoData = getLocalStorageItem('countryCode') && searchBy.search(getLocalStorageItem('countryCode'))

  const geo = geoData?.[0]?.geo

  const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI

  if (!isLoaded) return null

  return (
    <div className="map-design">
      <Card className="card-style">
        <CardContent>
          <Grid container spacing={2} className="map-div-height">
            <Grid item xs={12} className="map-main-div-grid map--checkbox-ui p-0">
              {searchThisArea && isMultiple ? (
                <div className="search--map-div-ui">
                  <Field
                    label="Search this area"
                    type="checkbox"
                    value={searchThisArea}
                    onChangeText={handleSearchArea}
                    name="searchThisArea"
                    labelIcon={<ReplayIcon />}
                  />
                </div>
              ) : (
                isMultiple && (
                  <div className="search--map-div-ui search--as--move--map-div-ui search--as--move--section">
                    <Field
                      label="Search as i move the map"
                      type="checkbox"
                      value={searchAsMapMove}
                      onChangeText={() => setSearchAsMapMove(!searchAsMapMove)}
                      name="searchAsMapMove"
                    />
                  </div>
                )
              )}

              {isRedirectToMap && googleDirectionUrl(geometricLocation) && (
                <div className="search--as--move--map-div-ui search--as--move--section">
                  <a className="inner--map-direction" href={googleDirectionUrl(geometricLocation)} target="_blank">
                    {gotoDirectionText}
                  </a>
                </div>
              )}

              <div style={mapStyle} className="map-class position-relative iframe-visible">
                <Map
                  mapContainerStyle={{ height: '100%' }}
                  className={`${
                    isRedirectToMap || isMultiple ? 'map--full--screen--icon' : ''
                  } responsive-map map-with-info map--resize`}
                  google={google}
                  zoom={11}
                  minZoom={2}
                  style={mapStyle}
                  {...(isMultiple && { onIdle: onIdle })}
                  initialCenter={{ lat: geo?.latitude, lng: geo?.longitude }}
                  {...(getCenter && { center: getCenter })}
                  {...(bounds && { bounds: bounds })}
                  onClick={onMapClick}
                  disableDefaultUI={props.isMapControl}
                  zoomControl
                  zoomControlOptions={{
                    position: google.maps.ControlPosition[zoomControlPos || 'LEFT_TOP'],
                  }}
                  streetViewControl
                  streetViewControlOptions={{
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  }}
                  // restriction={{
                  //   latLngBounds: { north: maxLat, south: -maxLat },
                  //   strictBounds: true
                  // }}
                >
                  {displayMarkers()}
                  {isNotChat && markerInfoWindow()}
                </Map>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default GoogleMarker

GoogleMarker.defaultProps = {
  markers: [],
  isMapControl: true,
  highlightBoatId: '',
  fetchResultOnMapChange: () => null,
  isMultiple: false,
  isInnerPage: true,
  isRedirectToMap: false,
  geometricLocation: null,
  gotoDirectionText: 'Go to direction',
}
